import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useUpdateLeaveEntryMutation } from 'src/rtk/features/leaveManagement/leaveManagementApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

const initialValues: ApplyForLeave = {
    leave_type: '',
    start_date: '',
    end_date: '',
    no_of_days: '',
    leave_reason: '',
    submission_status: '',
};

interface EditProps {
    id?: string;
    setShowEditForm?: any;
    row?: any;
}

const ApplyForLeave = (props: EditProps) => {
    const { id, setShowEditForm, row } = props;
    console.log(row);

    const [updateLeaveEntry, { isSuccess, isError, error, isLoading }] = useUpdateLeaveEntryMutation();

    const [btnClicked, setBtnClicked] = useState<string>('');

    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [daysDifference, setDaysDifference] = useState<any>('');

    const buttonRef = useRef<any>();
    const draftButtonRef = useRef<any>();

    const { data: leaveTypeLookupData } = useGetLookupDataQuery('leave_type');

    const typeList: Lookup[] = (leaveTypeLookupData as any)?.data[0]?.childs || [];

    const allLeaveType: SelectOption[] =
        typeList?.map((type) => ({
            value: type.name,
            label: type.name,
        })) || [];

    const err: CustomError = error as CustomError;

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setValues,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            leave_type: Yup.string().required('Please Select Type!'),
            start_date: Yup.date().required('Start Date is Required!'),
            end_date: Yup.date().when("start_date", (start_date, schema) => {
                if (start_date) {
                    const fromDate = new Date(start_date);

                    return schema.required('End date is Required').min(fromDate, 'To date must be greater than or equal start date').nullable();
                }

                return schema;
            }),
            no_of_days: Yup.string().required('No of Days is Required!'),
            leave_reason: Yup.string().required('Enter Leave Reason!'),
        }),

        onSubmit: (values: any) => {
            const formData = new FormData();

            Object.keys(values).forEach((key) => {
                if (key === 'submission_status') {
                    if (btnClicked === 'submit') {
                        values[key] = 'Submit';
                    } else if (btnClicked === 'save_as_draft') {
                        values[key] = 'Draft';
                    }
                }
                formData.append(key, values[key]);
            });
            console.log(values);
            updateLeaveEntry({ data: values, id: row.id });
        },
    });

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            toast.success('Leave Entry Updated Successfully!');
            setShowEditForm(false);
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    const handleStartDate = (e: any) => {
        setStartDate(e.target.value);
    };

    const handleEndDate = (e: any) => {
        setEndDate(e.target.value);
    };

    useEffect(() => {
        if (startDate !== '' && endDate !== '') {
            const from = new Date(startDate);
            const to = new Date(endDate);

            if (from <= to) {
                const differenceInTime = to.getTime() - from.getTime();
                const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                setDaysDifference(Math.round(differenceInDays) + 1);
            } else {
                setDaysDifference('');
            }
        }

    }, [startDate, endDate]);

    useEffect(() => {
        if (daysDifference > 0) {
            setFieldValue('no_of_days', daysDifference);
        }
        else {
            setFieldValue('no_of_days', '');
        }
    }, [daysDifference]);

    useEffect(() => {
        setStartDate(row?.start_date);
        setEndDate(row?.end_date);
        if (row) {
            setValues({
                leave_type: row?.leave_type || '',
                start_date: row?.start_date || '',
                end_date: row?.end_date || '',
                no_of_days: row?.no_of_days || '',
                leave_reason: row?.leave_reason || '',
                submission_status: row?.submission_status || '',
            });
        }
    }, [row]);

    return (
        <React.Fragment>
            {isLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                onReset={(e) => {
                    setShowEditForm(true);
                    resetForm();
                    setDaysDifference('');
                }}
            >
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="start_date" className="form-label required-field">From Date</Label>
                            <InputField
                                type="date"
                                name='start_date'
                                onChange={(e: any) => {
                                    handleChange(e);
                                    handleStartDate(e);
                                }}
                                onBlur={handleBlur}
                                value={values.start_date}
                                invalid={{ errors, touched }}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="end_date" className="form-label required-field">To Date</Label>
                            <InputField
                                type="date"
                                name='end_date'
                                onChange={(e: any) => {
                                    handleChange(e);
                                    handleEndDate(e);
                                }}
                                onBlur={handleBlur}
                                value={values.end_date}
                                invalid={{ errors, touched }}
                            />
                        </FormGroup>
                    </Col>

                    <Col sm={6}>
                        <FormGroup>
                            <Label for="no_of_days" className="form-label required-field">No. Of Days</Label>
                            <InputField
                                type="text"
                                placeholder='No. Of Days'
                                name='no_of_days'
                                value={daysDifference ? daysDifference : values.no_of_days}
                                disabled
                                invalid={{ errors, touched }}
                            />
                        </FormGroup>
                    </Col>

                    <Col sm={6}>
                        <FormGroup>
                            <Label for="leave_type" className="form-label required-field">Leave Type</Label>
                            <InputField
                                type="select"
                                options={allLeaveType}
                                name='leave_type'
                                value={values.leave_type}
                                onChange={handleChange}
                                invalid={{ errors, touched }}
                            />
                        </FormGroup>
                    </Col>

                    <Col sm={12}>
                        <FormGroup>
                            <Label for="leave_reason" className="form-label required-field">Reason</Label>
                            <InputField
                                type="textarea"
                                placeholder='Enter Reason'
                                {...getFieldProps('leave_reason')}
                                invalid={{ errors, touched }}
                            />
                        </FormGroup>
                    </Col>
                    <FormGroup className="mb-3 text-center">
                        <Button
                            type="reset"
                            className="btn btn-danger me-2"
                        >
                            Reset
                        </Button>
                        <button
                            type="submit"
                            className="btn btn-info me-2"
                            ref={draftButtonRef}
                            onClick={(e) => {
                                setBtnClicked('save_as_draft');
                            }}
                        >
                            Save as Draft
                        </button>
                        <button
                            type="submit"
                            ref={buttonRef}
                            className="btn btn-success"
                            onClick={(e) => {
                                setBtnClicked('submit');
                            }}
                        >
                            Update
                        </button>
                    </FormGroup>
                </Row>
            </Form>
        </React.Fragment>
    );
};

export default ApplyForLeave;
