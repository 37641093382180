import AllowanceForm from 'src/pages/TrainingAllowance/AllowanceForm';
import TrainingAllowanceListForACCOUNTS from 'src/pages/TrainingAllowance/TrainingAllowanceListForACCOUNTS';
import TrainingAllowanceListForAdmin from 'src/pages/TrainingAllowance/TrainingAllowanceListForAdmin';
import TrainingAllowanceListForBCPS from 'src/pages/TrainingAllowance/TrainingAllowanceListForBCPS';
import TrainingAllowanceListForTrainee from 'src/pages/TrainingAllowance/TrainingAllowanceListForTrainee';

export const traininAllowanceRoutes = [
  {
    path: '/training-allowance-application',
    component: TrainingAllowanceListForTrainee,
  },  
  {
    path: '/training-allowance-list',
    component: TrainingAllowanceListForAdmin,
  },  
  {
    path: '/training-allowance-list-bcps',
    component: TrainingAllowanceListForBCPS,
  },  
  {
    path: '/training-allowance-list-accounts',
    component: TrainingAllowanceListForACCOUNTS,
  },  
];
