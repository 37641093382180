import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import { useGetNoticeListByPaginateQuery } from 'src/rtk/features/notice/publishNoticeApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoGlobalApi';
import AddNotice from './AddNotice';
import EditNotice from './EditNotice';
import { UpdatedViewNoticePdf } from './UpdatedViewNoticePdf';
import loader from '../../assets/images/loader.gif';

interface SearchFields {
  from_date?: string;
  to_date?: string;
  department_id?: string;
  notice_type?: string;
  notice_no?: string;
  notice_subject?: string;
}

const initialValues: SearchFields = {
  from_date: '',
  to_date: '',
  department_id: '',
  notice_type: '',
  notice_no: '',
  notice_subject: '',
};

const NoticeList = () => {
  document.title = 'Notice Entry | BCPS';
  const { id: courseId } = useParams<{ id: string }>();

  const [modalShow, setModalShow] = useState(false);
  const [editCount, setEditCount] = useState<number>(1);
  const [noticeToEdit, setNoticeToEdit] = useState<NoticeList>(
    {} as NoticeList,
  );
  const [page, setPage] = useState<string | number>('1');
  const [paginate_per_page, set_paginate_per_page] = useState(15);
  const [search, setSearch] = useState('');
  const [noticeId, setNoticeId] = useState<string>('');

  const {
    data: noticeListData,
    error: noticeListDataError,
    isLoading: isNoticeListDataLoading,
    isError: isNoticeListDataError,
    isSuccess: isNoticeListDataSuccess,
  } = useGetNoticeListByPaginateQuery({
    page,
    paginate_per_page,
    search,
  });

  // const {
  //   data: departments,
  //   error: departmentsError,
  //   isLoading: isDepartmentsLoading,
  //   isError: isDepartmentsError,
  //   isSuccess: isDepartmentsSuccess,
  // } = useGetDepartmentsQuery();

  const { data: subjectList, isError: isSubFetchError } =
    useGetSubjectInfosQuery();

  // const departmentOptions: SelectOption[] = (departments?.data || []).map(
  //   (item) => ({
  //     value: item.id ?? '',
  //     label: item.department_name ?? '',
  //   }),
  // );

  const departmentOptions: SelectOption[] = (
    subjectList?.data || []
  ).map((item) => ({
    value: item.subject_name ?? '',
    label: item.subject_name ?? '',
  }));

  departmentOptions.push({ value: 'All', label: 'All' });

  const noticeTypeOptions: SelectOption[] =
    useGetLookupDataQuery('notice_type')
      .data?.data[0]?.childs.map((item) => ({
        value: item.name,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  const allNotices = noticeListData?.data;
  // const allDepartments = departments?.data;

  const editHandler = (notice: NoticeList) => {
    setNoticeToEdit(notice);
    setEditCount((e: number) => e + 1);
    setModalShow(true);
  };

  const modalShowHandler = (modalState: boolean, logbook: LogbookEntryData) => {
    setModalShow(modalState);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    set_paginate_per_page(newPerPage);
    setPage(page);
  };

  const columns: TableColumn<any>[] = [
    {
      name: 'Sl. No.',
      width: '100px',
      cell: (row, index) => (+page - 1) * paginate_per_page + index + 1,
      center: true,
    },

    {
      name: 'Date',
      selector: (row) =>
        row.notice_date ? moment(row.notice_date).format('DD/MMM/YYYY') : '',
      sortable: false,
      center: true,
    },

    {
      name: 'Notice No.',
      selector: (row) => row.notice_no,
      sortable: false,
      wrap: true,
      center: true,
    },
    
    {
      name: 'Notice Title',
      selector: (row) => row.notice_subject,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: 'Notice Type',
      selector: (row) => row.notice_type,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: 'Subject',
      selector: (row) =>
        row.department_id ?? '-',
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: 'Action',
      width: '150px',
      cell: (row) => (
        <>
          {/* btn-group-example */}
          <FormGroup className="btn-group mt-2">
            <button
              className="btn btn-sm btn-info"
              onClick={() => {
                setNoticeId(row.id);
              }}
            >
              <span className="fas fa-eye"></span> View
            </button>
            {
              <Button
                size="sm"
                color="success"
                type="button"
                text={`<span class="fas fa-edit" ></span> Edit`}
                onClick={() => {
                  editHandler(row);
                }}
              />
            }
            {noticeId === row.id ? (
              <UpdatedViewNoticePdf data={row} noticeId={noticeId} setNoticeId={setNoticeId} />
            ) : null}
          </FormGroup>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.from_date !== '') {
        s = s + `&from_date=${values.from_date}`;
      }

      if (values.to_date !== '') {
        s = s + `&to_date=${values.to_date}`;
      }

      if (values.department_id !== '') {
        s = s + `&department_id=${values.department_id}`;
      }

      if (values.notice_type !== '') {
        s = s + `&notice_type=${values.notice_type}`;
      }

      if (values.notice_no !== '') {
        s = s + `&notice_no=${values.notice_no}`;
      }

      if (values.notice_subject !== '') {
        s = s + `&notice_subject=${values.notice_subject}`;
      }

      setSearch(s);
      setPage('1');
      set_paginate_per_page(15);
    },
  });

  // useEffect(() => {
  //   if (isNoticeListDataLoading || !isNoticeListDataSuccess) {
  //     toast.dismiss();
  //     toast.loading('Data Loading...');
  //   } else {
  //     toast.dismiss();
  //     toast.success('Data Load Successfully');
  //   }
  // }, [isNoticeListDataLoading, isNoticeListDataSuccess]);

  return (
    <div className="page-content">
      {isNoticeListDataLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <AddNotice />
      <Container fluid>
        <Card className="mt-5 mb-5">          
          <CardBody>
            <Form
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                //   handleSubmit();
              }}
            >
              <div className="filter-wrapper">
                <FormGroup>
                  <Label for="from_date">From Date</Label>
                  <InputField
                    type="date"
                    invalid={{ errors, touched }}
                    {...getFieldProps('from_date')}
                  />
                </FormGroup>

                {/* <FormGroup>
                        <Label for="type">Type</Label>
                        <InputField
                          type="select"
                          placeholder=""
                          options={[]}
                          invalid={{ errors, touched }}
                          {...getFieldProps('type')}
                        />
                      </FormGroup> */}

                <FormGroup>
                  <Label for="to_date">To Date</Label>
                  <InputField
                    type="date"
                    invalid={{ errors, touched }}
                    {...getFieldProps('to_date')}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="department_id">Subject</Label>
                  <InputField
                    type="select"
                    options={departmentOptions}
                    invalid={{ errors, touched }}
                    {...getFieldProps('department_id')}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="notice_type">Notice Type</Label>
                  <InputField
                    type="select"
                    options={noticeTypeOptions}
                    invalid={{ errors, touched }}
                    {...getFieldProps('notice_type')}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="notice_no">Notice No.</Label>
                  <InputField
                    type="text"
                    invalid={{ errors, touched }}
                    {...getFieldProps('notice_no')}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="notice_subject">Notice Title</Label>
                  <InputField
                    type="text"
                    invalid={{ errors, touched }}
                    {...getFieldProps('notice_subject')}
                  />
                </FormGroup>

                <FormGroup className="btn-group btn-group-example mb-3">
                  <Button
                    color="danger"
                    type="reset"
                    text="Reset"
                    onClick={() => {
                      resetForm();
                      handleSubmit();
                    }}
                  />
                  <Button
                    type="button"
                    text="Search"
                    onClick={() => {
                      handleSubmit();
                    }}
                  />
                </FormGroup>
              </div>
            </Form>
            <DataTable
              columns={columns}
              data={allNotices ? allNotices?.filter((n: any) => n.type === 'Notice') : []}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isNoticeListDataLoading}
              paginationTotalRows={noticeListData?.total}
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 15, 20, 50, 100, 200]}
            />

            <Modal
              onOpened={function () {
                toast.dismiss();
              }}
              isOpen={modalShow}
              scrollable={true}
              size="xl"
              toggle={() => {
                document.body.classList.add('no_padding');
              }}
              backdrop={'static'}
              modalTransition={{ timeout: 0 }}
              backdropTransition={{ timeout: 0 }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Edit Notice
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setModalShow(!modalShow);
                  }}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col>
                    {noticeToEdit && (
                      <EditNotice
                        key={editCount}
                        noticeToEdit={noticeToEdit}
                        setModalShow={setModalShow}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default NoticeList;
