import moment from 'moment';
import * as url from 'src/helpers/url_helper';
import * as Yup from 'yup';

const formObj: any = {
  debug: false,

  listAPIUrl: url.SCHEDULE_LIST_FOR_EXAMINER,
  emailSendAPIUrl: url.SEND_EMAIL_FOR_EXAMINER,
  approvalAPIUrl: url.THESIS_DISSERTATION_PROTOCOL_APPROVE_BY_ADMIN,
  reviewerAssignAPIUrl: url.THESIS_DISSERTATION_THESIS_EXAMINER_ASSIGN_BY_ADMIN,

  listUrl: '/demo/',
  addUrl: '/demo/add',
  updateUrl: '/thesis-dissertation-protocol-view/',

  showSearchForm: true,
  showCreateNewBtn: false,
  showUpdateBtn: false,
  showViewBtn: true,
  showDeleteBtn: false,
  showTableActionColumn: true,

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Save',
  updateBtnTitle: 'Comments',
  resetBtnTitle: 'Reset',
  draftBtnTitle: '',
  viewBtnTitle: 'View',
  deletBtnTitle: 'Delete',
  deletePromtMessage: 'Do you want to delete?',

  title: 'Scedule List For Admin',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [{ link: '/', name: 'Dashboard' }],

  searchFormFields: {
    category: {
      positionInForm: 1,
      label: 'Category',
      name: 'category',
      viewCell: (row: any) => row?.title?.category || '',
      cell: (row: any) => row?.title?.category || '',
      type: 'select',
      default: 'Dissertation',
      // isMulti: false,
      isRequired: false,
      validation: Yup.string()
        .required('Category is required')
        .oneOf(['Thesis', 'Dissertation']),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      data: [
        { label: 'Thesis', value: 'Thesis' },
        { label: 'Dissertation', value: 'Dissertation' },
      ],
    },

    title_of_thesis: {
      positionInForm: 2,
      label: 'Title',
      name: 'title_of_thesis',
      viewCell: (row: any) => row?.title?.title_of_thesis || '',
      cell: (row: any) => row?.title?.title_of_thesis || '',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 6,
    },
    bmdc_registration_no: {
      positionInForm: 3,
      label: 'BMDC NO',
      viewCell: (row: any) => row?.bmdc_registration_no || '',
      cell: (row: any) => row?.bmdc_registration_no || '',
      name: 'bmdc_registration_no',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 3,
    },

    full_name: {
      positionInForm: 3,
      label: 'Trainee Name',
      name: 'full_name',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 3,
      viewCell: (row: any) => row?.trainee_name || '',
      cell: (row: any) => row?.trainee_name || '',
    },
    // subject_name: {
    //   positionInForm: 4,
    //   name: 'full_name',
    //   type: 'text',
    //   default: '',
    //   isRequired: false,
    //   showInSearch: true,
    //   colSize: 3,
    //   label: 'Discipline',
    //   viewCell: (row: any) => row?.registration?.subject_name || '',
    //   cell: (row: any) => row?.registration?.subject_name || '',
    // },

    exam_date: {
      label: 'Exam Date',
      viewCell: (row: any) => row?.exam_date || '',
      cell: (row: any) => row?.exam_date || '',
    },

    examiner_ids: {
      label: 'Examiners',
      cell: (row: any) => row?.examiners.length || 0,
    },

    examiner_names: {
      label: 'Examiners Name',
      cell: (row: any) => row?.examiners?.map((examiner: any, index: number) => examiner?.examiner_name + (row?.examiners?.length === index + 1 ? '' : ',')) || '',
      viewCell: (row: any) => row?.examiners?.map((examiner: any, index: number) => examiner?.examiner_name + (row?.examiners?.length === index + 1 ? '' : ',')) || '',
    },

    exam_start_time: {
      label: 'Exam Time',
      viewCell: (row: any) => moment(row?.exam_start_time, "HH:mm:ss").format("hh:mm A") + " To " + moment(row?.exam_end_time, "HH:mm:ss").format("hh:mm A") || '',
      cell: (row: any) => moment(row?.exam_start_time, "HH:mm:ss").format("hh:mm A") + " To " + moment(row?.exam_end_time, "HH:mm:ss").format("hh:mm A") || '',
    },

    room_no: {
      label: 'Room no',
      viewCell: (row: any) => row?.room_no || '',
      cell: (row: any) => row?.room_no || '',
    },
    table_no: {
      label: 'Table no',
      viewCell: (row: any) => row?.table_no || '',
      cell: (row: any) => row?.table_no || '',
    },

    instruction: {
      label: 'Instruction',
      viewCell: (row: any) => row?.instruction || '',
    },


    admin_approval_status: {
      label: 'Admin Approval Status',
      viewCell: (row: any) => row?.admin_approval_status || '',
      cell: (row: any) => {
        switch (row?.admin_approval_status) {
          case 'Approve':
            return (
              <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                {'Approved'}
              </div>
            );
          case 'Pending':
            return (
              <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                {'Pending'}
              </div>
            );
          case 'Correction':
            return (
              <div className="badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium">
                {row?.admin_approval_status}
              </div>
            );
          case 'Reject':
            return (
              <div className="badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium">
                {'Rejected'}
              </div>
            );

          default:
            return (
              <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                {'Pending'}
              </div>
            );
        }
      },
      showInSearch: false,
    },

    guide_id: {
      label: 'Guide',
      name: 'guide_id',
      viewCell: (row: any) => row?.title?.guide?.personal_info?.full_name || '',
      type: 'modal',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Guide is required!'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        listAPIUrl: url.GET_GUIDE_COGUIDE_LIST + '?type=Guide&',
        displayLabel: (row: any) => row?.label || '',
        key: (row: any) =>
          row?.registration?.personal_info?.full_name
            ? row?.registration_no
            : false,
        label: (row: any) => {
          return row?.registration?.personal_info?.full_name || '';
        },
        showSearchForm: true,
        searchFormFields: [
          {
            label: 'BMDC NO',
            name: 'bmdc_registration_no',
            type: 'text',
            cell: (row: any) => row?.registration?.bmdc_registration_no || '',
            showInSearch: true,
          },
          {
            label: 'Name',
            name: 'full_name',
            type: 'text',
            cell: (row: any) =>
              row?.registration?.personal_info?.full_name || '',
            showInSearch: true,
          },

          {
            label: 'Institute Name',
            name: 'institute_name',
            type: 'text',
            cell: (row: any) =>
              row.registration?.employment_infos?.institute_name || '',
            showInSearch: true,
          },

          {
            label: 'Mobile',
            name: 'mobile',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.mobile || '',
            showInSearch: true,
          },
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.email || '',
            showInSearch: true,
          },
        ],
      },
      data: [],
    },

    co_guide_id: {
      label: 'Co-guide',
      name: 'co_guide_id',
      type: 'modal',
      default: '',
      isMulti: true,
      isRequired: true,
      validation: Yup.array()
        .ensure()
        .required('Co-guide is required!')
        .min(1, 'Select Minimum 1.')
        .max(3, 'Select Maximum 3'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        listAPIUrl: url.GET_GUIDE_COGUIDE_LIST + '?type=Co-Guide&',
        displayLabel: (row: any) => row?.label || '',
        key: (row: any) =>
          row?.registration?.personal_info?.full_name
            ? row?.registration_no
            : false,
        label: (row: any) => {
          return row?.title?.registration?.personal_info?.full_name || '';
        },
        showSearchForm: true,
        searchFormFields: [
          {
            label: 'BMDC NO',
            name: 'bmdc_registration_no',
            type: 'text',
            cell: (row: any) => row?.registration?.bmdc_registration_no || '',
            showInSearch: true,
          },
          {
            label: 'Name',
            name: 'full_name',
            type: 'text',
            cell: (row: any) =>
              row?.registration?.personal_info?.full_name || '',
            showInSearch: true,
          },

          {
            label: 'Institute Name',
            name: 'institute_name',
            type: 'text',
            cell: (row: any) =>
              row.registration?.employment_infos?.institute_name || '',
            showInSearch: true,
          },

          {
            label: 'Mobile',
            name: 'mobile',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.mobile || '',
            showInSearch: true,
          },
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.email || '',
            showInSearch: true,
          },
        ],
      },
      data: [],
    },

    guide_approval_note: {
      label: 'Guide approval note',
      viewCell: (row: any) => row?.guide_approval_note || '',
    },

    guide_approval_status: {
      label: 'Guide approval status',
      viewCell: (row: any) => row?.guide_approval_status || '',
    },

    co_guide_view: {
      label: 'Co-Guide',
      viewCell: (row: any) => {
        return (
          <div className="d-flex">
            {row?.co_guide_id_1 && (
              <div className="m-1 p-2 border rounded">
                <div>Name: {row?.title?.co_guide_1_name || ''}</div>
                <div>Note: {row?.co_guide1_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide1_approval_status || ''}
                </div>
              </div>
            )}
            {row?.co_guide_id_2 && (
              <div className="m-1 p-2 border rounded">
                <div>Name: {row?.title?.co_guide_2_name || ''}</div>
                <div>Note: {row?.co_guide2_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide2_approval_status || ''}
                </div>
              </div>
            )}
            {row?.co_guide_id_3 && (
              <div className="m-1 p-2 border rounded">
                <div>Name: {row?.title?.co_guide_3_name || ''}</div>
                <div>Note: {row?.co_guide3_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide3_approval_status || ''}
                </div>
              </div>
            )}
          </div>
        );
      },
    },

    examiners: {
      label: 'Name of Examiners ',
      viewCell: (row: any) => {
        return (
          <div className="d-flex">
            {row?.examiners && row?.examiners.map((item: any) => {
              return (
                <div key={item.id} className="m-1 p-2 border rounded col ">
                  <div>{item?.examiner_name || ''}</div>
                </div>
              )
            })}

          </div>
        );
      },
    },

    updated: {
      label: 'Date of Submission',
      viewCell: (row: any) => moment(row.updated_at).format("YYYY-MMM-DD"),
    },

    submission_status: {
      label: 'Submission Status',
      viewCell: (row: any) => {
        return (
          <div>
            {row?.submission_status === 'Approve' && (
              <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                {'Approved'}
              </div>
            )}
            {row?.submission_status === 'Submit' && (
              <div className="badge rounded-pill d-inline-block badge-applied font-size-12 fw-medium">
                {'Submitted'}
              </div>
            )}
            {row?.submission_status === 'Reject' && (
              <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                {'Rejected'}
              </div>
            )}
            {(row?.submission_status === 'Draft' || row?.submission_status === 'Pending' || row?.submission_status === null) && (
              <div className="badge rounded-pill d-inline-block badge-draft font-size-12 fw-medium">
                {row?.submission_status}
              </div>
            )}
          </div>
        );
      },
      cell: (row: any) =>
        <div>
          {row?.submission_status === 'Approve' && (
            <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
              {'Approved'}
            </div>
          )}
          {row?.submission_status === 'Submit' && (
            <div className="badge rounded-pill d-inline-block badge-applied font-size-12 fw-medium">
              {'Submitted'}
            </div>
          )}
          {row?.submission_status === 'Reject' && (
            <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
              {'Rejected'}
            </div>
          )}
          {(row?.submission_status === 'Draft' || row?.submission_status === 'Pending' || row?.submission_status === null) && (
            <div className="badge rounded-pill d-inline-block badge-draft font-size-12 fw-medium">
              {row?.submission_status}
            </div>
          )}
        </div>,
    },


  },
  formFields: {
    examiners_id: {
      label: 'Examiner',
      name: 'examiners_id',
      type: 'select',
      default: '',
      isMulti: true,
      isRequired: true,
      validation: Yup.string().ensure().required('Examinner 1 is required!'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
      dataApi: {
        url: url.THESIS_DISSERTATION_EXAMINER_LIST,
        key: 'id',
        label: 'name',
        label2: '',
      },
      data: [],
    },

    exam_date: {
      positionInForm: 3,
      label: 'Exam Date',
      name: 'exam_date',
      type: 'date',
      options: {
        mode: 'single', //range, multiple, single
        dateFormat: 'Y-m-d', // 'Y-m-d' 'd/m/Y'
        retDateFormat: 'YYYY-MM-DD', // 'YYYY-MM-DD' 'DD-MM-YYYY'
        // minDate: new Date(),
        // defaultDate: new Date(),
        allowInput: true,
        defaultDate: new Date(),
        // defaultDate: [values['period_from'], values['period_to']],
      },
      isRequired: true,
      validation: Yup.string().required('Exam Date is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },
    exam_start_time: {
      positionInForm: 3,
      label: 'Exam Start Time',
      name: 'exam_start_time',
      type: 'time',
      options: {
        mode: 'single', //range, multiple, single
        // dateFormat: 'H:i', // 'Y-m-d' 'd/m/Y'
        retDateFormat: 'h:i K', // 'YYYY-MM-DD' 'DD-MM-YYYY'
        // minDate: new Date(),
        // defaultDate: new Date(),
        allowInput: true,
        defaultDate: '',
        noCalendar: true,
        enableTime: true,
        dateFormat: 'h:i K'
        // defaultDate: [values['period_from'], values['period_to']],
      },
      isRequired: true,
      validation: Yup.string().required('Exam Start Time is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },
    exam_end_time: {
      positionInForm: 3,
      label: 'Exam End Time',
      name: 'exam_end_time',
      type: 'time',
      options: {
        mode: 'single', //range, multiple, single
        // dateFormat: 'H:i', // 'Y-m-d' 'd/m/Y'
        retDateFormat: 'h:i K', // 'YYYY-MM-DD' 'DD-MM-YYYY'
        // minDate: new Date(),
        // defaultDate: new Date(),
        allowInput: true,
        defaultDate: '',
        noCalendar: true,
        enableTime: true,
        dateFormat: 'h:i K'
        // defaultDate: [values['period_from'], values['period_to']],
      },
      isRequired: true,
      validation: Yup.string().required('Exam End Time is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },

    building: {
      positionInForm: 3,
      label: 'Building',
      name: 'building',
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Building is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },
    room_no: {
      positionInForm: 3,
      label: 'Room no',
      name: 'room_no',
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Room no is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },
    table_no: {
      positionInForm: 3,
      label: 'Table no',
      name: 'table_no',
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Table no is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },


    instruction: {
      label: 'Instruction',
      name: 'instruction',
      type: 'textarea',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Instruction is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },



  },
};

export default formObj;
