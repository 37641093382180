import { useFormik } from 'formik';
import { Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from 'yup';

interface Props {
  isOpen: boolean;
  toggle: any;
  infoId: any;
  getAllowanceApproval: any;
}
const initialValues = {
  id: '',
  status: 'Approve',
};

const ApprovalModal = (props: Props) => {

  const { isOpen, toggle, infoId, getAllowanceApproval } = props;

  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      const approveUrl = `${infoId}/${values.status}`;
      getAllowanceApproval(approveUrl);
    },
  });


  return (
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      toggle={toggle}
      size="md"
    >
      <ModalHeader toggle={toggle}>Approval Status</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <ModalBody>
          <div className="col">
            <FormGroup check inline className="mb-3 ms-2">
              <input
                className="form-check-input"
                type="radio"
                name="status"
                id="Approve"
                onChange={(e) => {
                  setFieldValue('status', 'Approve');
                }}
                checked={values.status === 'Approve'}
              />
              <label
                className="form-check-label text-success"
                htmlFor="Approve"
              >
                Eligible
              </label>
            </FormGroup>
            <FormGroup check inline className="mb-3 ">
              <input
                className="form-check-input"
                type="radio"
                name="status"
                id="Reject"
                onChange={() => {
                  setFieldValue('status', 'Reject');
                }}
              />
              <label
                className="form-check-label text-danger"
                htmlFor="Reject"
              >
                Not Eligible
              </label>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex gap-1">
            <button
              type="submit"
              className="btn btn-primary"
            >
              Save
            </button>

            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ApprovalModal;
