import { toast } from 'react-hot-toast';

const AddLogbookEntryDetailsOptions = (props: any) => {
  const { formikProp, inputList, setInputList, setModalShow } = props;

  const handleAddFields = () => {
    const values = [...inputList];
    values.push({ label: '', value: '' });
    setInputList(values);
  };

  const handleRemoveFields = (index: number) => {
    const values = [...inputList];
    values.splice(index, 1);
    setInputList(values);
  };

  const handleInputChange = (index: any, event: any) => {
    const { name, value } = event.target;

    const values = [...inputList];
    values[index][name] = value;
    setInputList(values);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    formikProp.values['options'] = inputList;
    toast.success('Options added successfully');
    setModalShow(false);
  };

  const handleReset = (e: any) => {
    setInputList([{ label: '', value: '' }]);
  };

  console.log(inputList);

  return (
    <>
      {inputList.map((input: any, index: any) => (
        <div key={index} className="row mb-3">
          <div className="col-sm-5">
            <label className="form-label">Label</label>
            <input
              type="text"
              className="form-control"
              placeholder="Label"
              value={inputList[index]['label']}
              name="label"
              onChange={(event) => handleInputChange(index, event)}
            />
          </div>
          <div className="col-sm-5">
            <label className="form-label">Value</label>
            <input
              type="text"
              className="form-control"
              placeholder="Value"
              value={inputList[index]['value']}
              name="value"
              onChange={(event) => handleInputChange(index, event)}
            />
          </div>
          <div className="col-sm-2 mt-4">
            {inputList?.length > 1 ? (
              <button
                type="button"
                className={'btn btn-danger mb-2'}
                onClick={() => handleRemoveFields(index)}
              >
                <i className="fa fa-minus-circle" />
              </button>
            ) : (
              <button
                type="button"
                className={'btn btn-danger mb-2'}
                onClick={() => handleRemoveFields(index)}
                disabled={true}
              >
                <i className="fa fa-minus-circle" />
              </button>
            )}
            <br />
            {index === inputList.length - 1 && (
              <button
                type="button"
                className={'btn btn-info'}
                onClick={handleAddFields}
              >
                <i className="fa fa-plus-circle" />
              </button>
            )}
          </div>
          {index === inputList.length - 1 && (
            <div className="col-sm-12">
              <button
                type="reset"
                className={'btn btn-warning me-2'}
                onClick={handleReset}
              >
                Reset
              </button>
              <button
                type="submit"
                className={'btn btn-primary'}
                onClick={handleSubmit}
                disabled={inputList?.map((input: any) => input?.label).includes('') || inputList?.map((input: any) => input?.value).includes('')}
              >
                Save
              </button>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default AddLogbookEntryDetailsOptions;
