import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import DisplayImage from 'src/components/Common/displayImage';
import { config } from 'src/config';
import * as url from 'src/helpers/url_helper';
import {
  useGetELogBooksQuery,
  useUpdateELogBookMutation,
} from 'src/rtk/features/e-log-book/eLogBookApi';
import { useGetFormByLogbookTypeQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import * as Yup from 'yup';
import loader from '../../../assets/images/loader.gif';
import { actionColumnStyles, dataTableCustomStyles } from '../../../components/Common/FixedActionColumn';
import { useAppSelector } from 'src/rtk/app/hook';

const ELogBookSuperVisorList = () => {
  let { id: formValue } = useParams<{ id: string }>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [submitting, setSubmittingl] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [logDetails, setLogDetails] = useState<any>({});
  const [extraDetails, setExtraDetails] = useState<any[]>([]);
  const [note, setNote] = useState<string>('');
  const [formName, setFormName] = useState<string>('');

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `${url.SUPER_VISOR_LOGBOOK_ENTRY_DETAILS}?page=${page}&per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  //  const handleSort = (column: string, sortDirection: string) => {
  //    console.log(column, sortDirection);
  //  };

  const {
    data: eLogBooks,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
  } = useGetELogBooksQuery(generateQueryUrl());
  const data: any = eLogBooks?.data || [];

  // useEffect(() => {
  //   if (dataListLoading || !dataListLoadingSuccess) {
  //     toast.dismiss();
  //     toast.loading('Data Loading...');
  //   } else {
  //     toast.dismiss();
  //     toast.success('Data Load Successfully');
  //   }
  // }, [dataListLoading, dataListLoadingSuccess]);

  const [updateELogBook, { isSuccess, isLoading, isError, error }] =
    useUpdateELogBookMutation();
  const err = error as CustomError;

  const initialValues: eLogBookStatus = {
    id: '',
    status: '',
    message: '',
  };
  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      status: Yup.string().required('Status is required'),
      message: Yup.string().when('status', (status, schema) => {
        if (status && status !== 'Approved') {
          return schema.required(status + ' note is Required!');
        }
        return schema;
      }),
    }),
    onSubmit: (values) => {
      // toast.loading('Loading...');
      setSubmittingl(true);
      updateELogBook(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setSubmittingl(false);
      resetForm();
      setShowModal(!showModal);
      toast.dismiss();
      toast.success('Updated Successfully');
    }
    if (isError) {
      setSubmittingl(false);
      toast.dismiss();
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  const viewHandler = (
    data: any = {},
    detailsId: string,
    status: string = '',
    note: string,
  ) => {
    toast.loading('Logbook Details Loading...');
    setShowModal(!showModal);
    resetForm();
    let pData = data;
    values['id'] = detailsId;
    values['status'] = '';
    setNote(note);
    if (
      status === 'Approved' ||
      status === 'Reject' ||
      status === 'Correction'
    ) {
      setShowForm(false);
    } else {
      setShowForm(true);
    }

    console.log(pData);
    setLogDetails(pData);
  };

  const print = (data: any[]) => {
    console.log(data);
  };

  const getDetailsData = (key = '', dataObj: any = {}) => {
    let perseData: any = {};

    if (
      dataObj['details_json'] &&
      typeof dataObj['details_json'] === 'string'
    ) {
      perseData = JSON.parse(dataObj.details_json);
    } else {
      perseData = dataObj;
    }

    return perseData[key] ? (
      <span dangerouslySetInnerHTML={{ __html: perseData[key] }}></span>
    ) : perseData[key.toLowerCase()] ? (
      <span dangerouslySetInnerHTML={{ __html: perseData[key] }}></span>
    ) : (
      ''
    );
  };

  interface SearchFields {
    form?: string;
    type?: string;
    from_date?: string;
    to_date?: string;
    approved_status?: string;
    bmdc_reg_no?: string;
  }

  const searchInitialValues: SearchFields = {
    form: '',
    type: '',
    from_date: '',
    to_date: '',
    approved_status: '',
    bmdc_reg_no: '',
  };

  const {
    handleSubmit: searchSubmit,
    getFieldProps: searchGetFieldProps,
    values: searchValues,
    // touched,
    // errors,
    setFieldValue: setSearchFieldValue,
    resetForm: searchResetForm,
    // setErrors,
    // isSubmitting,
  } = useFormik({
    initialValues: searchInitialValues,

    onSubmit: (searchValues) => {
      let s: string = '';

      if (searchValues.bmdc_reg_no !== '') {
        s = s + `&bmdc_reg_no=${searchValues.bmdc_reg_no}`;
      }

      if (searchValues.form !== '') {
        s = s + `&form=${searchValues.form}`;
      }

      if (searchValues.from_date !== '') {
        s = s + `&from_date=${searchValues.from_date}`;
      }

      if (searchValues.to_date !== '') {
        s = s + `&to_date=${searchValues.to_date}`;
      }

      if (searchValues.approved_status !== '') {
        s = s + `&approved_status=${searchValues.approved_status}`;
      }

      formValue = '';
      setSearch(s);
      setPage('1');
      setFormName('');
      setLimit(10);
    },
  });

  useEffect(() => {
    if(authUser?.type !== 'Operation'){
      setFormName(formValue);
      setSearchFieldValue('approved_status', 'Pending');
  
      let s: string = '';
      s = s + `&approved_status=${'Pending'}`;
      setSearch(s);
    }
  }, []);

  useEffect(() => {
    if (formName) {
      setSearchFieldValue('form', (['In-Patient Management', 'Out-Patient Management', 'Emergency Patient Management'].includes(formName) ? 'Patient Management' : ['Patient Management', 'Procedural and Interpretative Skills', 'Academic Activity', 'Clinical Performance and Surgical Skill'].includes(formName) ? formName : formName ? 'Academic Activity' : ''));

      let s: string = '';
      s = s + (['In-Patient Management', 'Out-Patient Management', 'Emergency Patient Management'].includes(formName) ? `&form=${'Patient Management'}` : ['Patient Management', 'Procedural and Interpretative Skills', 'Academic Activity', 'Clinical Performance and Surgical Skill'].includes(formName) ? `&form=${formName}` : formName ? `&form=${'Academic Activity'}` : '');
      setSearch(s);
    }
  }, [formName]);

  const { data: formSetups, isLoading: formSetupsLoading } =
    useGetFormByLogbookTypeQuery(2); // 2 for Academic Activities
  const forms =
    (formSetups?.data && formSetups?.data[0]?.entry_setup_master) || [];
  let formOptions: SelectOption[] = [
    ...forms?.map((e: LogbookEntrySetupMaster) => ({
      label: e.particulars!,
      value: e.particulars!,
    })),
  ];

  const decodeBase64EncodeString = (base64String: string) => {
    let correctBase64EncodedString: string = base64String.split("base64,")?.[1];
    let tempString: string = base64String.split("base64,")?.[0];
    let typeOfDocument: string = tempString.split("data:")?.[1] + ';base64';
    let byteCharacters = atob(correctBase64EncodedString);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: typeOfDocument });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };
  const authUser = useAppSelector((state) => state.auth.user);

  const columns: TableColumn<any>[] = [
    {
      name: '#',
      width: '50px',
      cell: (row, index) => <strong>{data?.from + index}</strong>,
    },
    {
      name: 'Photo',
      selector: (row) =>
        row?.logbook_entry_master?.registration_user_info?.personal_info?.picture,
      cell: (row, index) => (
        <>
          {row?.logbook_entry_master?.registration_user_info?.personal_info?.picture ? (
            <DisplayImage
              className="img-thumbnail my-1 rounded-circle avatar-md"
              src={`${config?.APP_URL}${row?.logbook_entry_master?.registration_user_info?.personal_info?.picture}`}
            />
          ) : (
            ''
          )}
        </>
      ),
      width: "100px",
    },

    {
      name: 'Slot',
      selector: (row) =>
        row?.logbook_entry_master?.registration_user_info?.current_session_and_supervisor_info?.slot_info?.name,
      wrap: true,
    },
    {
      name: 'Date',
      selector: (row) => row?.created_at,
      cell: (row, index) =>
        row?.created_at ? moment(row?.created_at).format('DD/MMM/YYYY h:mm:ss a') : '',
      wrap: true,
    },

    {
      name: 'Form',
      selector: (row) => row?.heading,
      wrap: true,
    },

    {
      name: 'Trainee Name',
      selector: (row) =>
        row?.logbook_entry_master?.registration_user_info?.personal_info?.full_name,
      wrap: true,
    },

    {
      name: 'BMDC No.',
      selector: (row) =>
        row?.logbook_entry_master?.registration_user_info?.bmdc_registration_no,
      wrap: true,
    },

    // {
    //   name: 'Reg. No.',
    //   selector: (row) =>
    //     row.logbook_entry_master.registration_user_info.registration_no,
    // },

    {
      name: 'Subject',
      selector: (row) => row?.logbook_entry_master?.subject_name,
      wrap: true,
    },

    // {
    //   name: 'Form Heading',
    //   selector: (row) => getDetailsData('form', row),
    // },

    // {
    //   name: 'Patient Name',
    //   selector: (row) => getDetailsData('Patient Name', row),
    // },
    // {
    //   name: 'Reg No',
    //   selector: (row) => getDetailsData('Reg No', row),
    // },
    // {
    //   name: 'Diagnosis/Findings',
    //   selector: (row) => getDetailsData('Diagnosis/Findings', row),
    // },
    ...(authUser?.type !== 'Operation' ? [
    {
      name: 'Status',
      selector: (row: any) => row?.approved_status,
      cell: (row: any) => {
        switch (row?.approved_status?.toLowerCase()) {
          case 'reject':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium">
                  {'Rejected'}
                </div>
              </>
            );

          case 'draft':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium">
                  {row?.approved_status}
                </div>
              </>
            );

          case 'approved':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                  {row?.approved_status}
                </div>
              </>
            );

          case 'correction':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-correction font-size-12 fw-medium">
                  {row.approved_status}
                </div>
              </>
            );

          case 'pending':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                  {row?.approved_status}
                </div>
              </>
            );

          default:
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                  {row?.approved_status}
                </div>
              </>
            );
        }
      },
    } ]: []),

    ...(authUser?.type !== 'Operation' ? [
    {
      name: <div>Action</div>,
      selector: (row: any) => row?.id,
      style: actionColumnStyles,
      cell: (row: any, index: number) => (
        <>
          <Button
            size="sm"
            color="info"
            type="button"
            text={`<span className="fas fa-eye" ></span> View`}
            onClick={() => {
              let traineeDetails = row?.logbook_entry_master || {};

              let logbookDetails = JSON.parse(row?.details_json) || {};
              let newDateTime = moment(row?.created_at).format('DD-MMM-YYYY h:mm:ss a') || '';

              let detailsObj = {
                'Trainee Details': traineeDetails,
                'Logbook Details': logbookDetails,
                'DateTime': newDateTime,
              };

              let detailsId = row?.id;
              let approved_status = row?.approved_status;
              let note = row?.note;
              viewHandler(detailsObj, detailsId, approved_status, note);
            }}
          />
        </>
      ),
    } ] : []),
  ];

  document.title = 'E-Logbook Entry List For Approval | BCPS';

  return (
    <div className="page-content">
      {dataListLoading ? (
        <div className='overlay'>
          <img src={loader} alt='Loading' height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          title="E-Logbook Entry List For Approval"
        />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div className="gap-2 ">
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      searchSubmit();
                    }}
                  >
                    <div className="filter-wrapper mb-0">
                      <FormGroup>
                        <Label for="bmdc_reg_no">BMDC No.</Label>
                        <InputField
                          type="text"
                          placeholder="Search.."
                          {...searchGetFieldProps('bmdc_reg_no')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="form">Form</Label>
                        <InputField
                          type="select"
                          placeholder="Search.."
                          options={[
                            { label: 'Patient Management', value: 'Patient Management' },
                            { label: 'Procedural and Interpretative Skills', value: 'Procedural and Interpretative Skills' }, 
                            { label: 'Academic Activity', value: 'Academic Activity' },
                            { label: 'Clinical Performance and Surgical Skill', value: 'Clinical Performance and Surgical Skill' },
                          ]}
                          invalid={{ errors, touched }}
                          {...searchGetFieldProps('form')}
                        />
                      </FormGroup>

                      {/* <FormGroup>
                            <Label for="type">Type</Label>
                            <InputField
                              type="select"
                              placeholder=""
                              options={[]}
                              invalid={{ errors, touched }}
                              {...getFieldProps('type')}
                            />
                          </FormGroup> */}

                      <FormGroup>
                        <Label for="from_date">From Date</Label>
                        <InputField
                          type="date"
                          placeholder=""
                          invalid={{ errors, touched }}
                          {...searchGetFieldProps('from_date')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="to_date">To Date</Label>
                        <InputField
                          type="date"
                          placeholder=""
                          invalid={{ errors, touched }}
                          {...searchGetFieldProps('to_date')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="status">Status</Label>
                        <InputField
                          type="select"
                          placeholder=""
                          options={[
                            { label: 'Approved', value: 'Approved' },
                            // { label: 'Draft', value: 'Draft' },
                            { label: 'Pending', value: 'Pending' },
                            { label: 'Reject', value: 'Reject' },
                            { label: 'Correction', value: 'Correction' },
                          ]}
                          invalid={{ errors, touched }}
                          {...searchGetFieldProps('approved_status')}
                        />
                      </FormGroup>

                      <FormGroup className="btn-group btn-group-example mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          text="Reset"
                          onClick={() => {
                            searchResetForm();
                            // setSearchFieldValue('approved_status', 'Pending');
                            searchSubmit();
                          }}
                        />

                      </FormGroup>
                      <FormGroup className='btn-group btn-group-example mb-3'>
                        <Button
                          type="button"
                          text="Search"
                          onClick={() => {
                            searchSubmit();
                          }}
                        />
                      </FormGroup>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
            <DataTable
              // title="Academic Activities List"
              columns={columns}
              data={data.data ? data.data : []}
              customStyles={dataTableCustomStyles}
              pagination
              paginationServer
              // selectableRows
              // sortServer
              // onSort={handleSort}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={dataListLoading}
              paginationTotalRows={data.total}
              // expandableRows
              // expandableRowsComponent={ExpandedComponent}
              // fixedHeader
              // fixedHeaderScrollHeight="500px"
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              persistTableHead
            />
          </CardBody>
        </Card>
      </Container>
      <Modal
        onOpened={function () {
          toast.dismiss();
        }}
        isOpen={showModal}
        scrollable={false}
        size="lg"
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <Form
          className="custom-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          onReset={(e) => {
            e.preventDefault();
            resetForm();
          }}
        >
          <div className="modal-header bg-light">
            <h5 className="modal-title" id="staticBackdropLabel">
              Logbook Details
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowModal(!showModal);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-0">
            {isLoading ? (
              <div className='overlay'>
                <img src={loader} alt='Loading' height={100} width={300} />
              </div>
            ) : null}
            <div className="bordered mb-3 p-3">
              {Object.keys(logDetails).length > 0 && (
                <>
                  <Row>
                    <Col sm={8} className="table-responsive">
                      <table className="table table-bordered table-striped table-sm border-light table-hover text-capitalize">
                        {/* <thead>
                          <tr className="bg-light text-dark text-center">
                            <th colSpan={2}>Logbook Details</th>
                          </tr>
                        </thead> */}
                        <tbody>
                          {Object.keys(logDetails['Logbook Details'])
                            .map(
                              (item2: any, key2) =>
                                ![
                                  'id',
                                  'supervisor_bmdc_registration_no',
                                  'supervisor_name',
                                  'supervisor_registration_no',
                                  'entry_setup_master_id',
                                  'competency_group',
                                ].includes(item2) && (
                                  <tr key={'details-' + key2.toString()}>
                                    <th
                                      className=""
                                      dangerouslySetInnerHTML={{
                                        __html: item2
                                          .replace(/([A-Z])/g, ' $1')
                                          .trim()
                                          .replaceAll('_', ' '),
                                      }}
                                      style={{ width: '35%' }}
                                    ></th>
                                    <td>
                                      {typeof logDetails['Logbook Details'][item2] === 'string' &&
                                        logDetails['Logbook Details'][item2]?.toLowerCase()?.includes('base64') ?
                                        (
                                          <a
                                            className='btn btn-sm btn-outline-info'
                                            rel='noreferrer'
                                            target='_blank'
                                            onClick={(e: any) => {
                                              decodeBase64EncodeString(logDetails['Logbook Details'][item2]);
                                            }}
                                          >
                                            View
                                          </a>
                                        )
                                        :
                                        (
                                          item2 === 'Date' ? logDetails['DateTime'] : logDetails['Logbook Details'][item2]
                                        )}
                                    </td>
                                  </tr>
                                ),
                            )}
                          <>
                            {logDetails['Logbook Details']?.competency_group?.length && logDetails['Logbook Details']?.competency_group?.length > 0 && (
                              <tr>
                                <th
                                  // className="bg-light"
                                  style={{ width: '25%' }}
                                  dangerouslySetInnerHTML={{
                                    __html: 'Competency Group',
                                  }}
                                ></th>
                                <td>
                                  {logDetails['Logbook Details']?.competency_group?.map((competency: any) => {
                                    return (
                                      <div key={competency?.id}>
                                        {competency?.name}{competency?.level?.length && competency?.level?.length > 0 ? ' (' : ''}
                                        {competency?.level?.includes(1)
                                          ? competency?.level?.includes(2) ||
                                            competency?.level?.includes(3) ||
                                            competency?.level?.includes(4)
                                            ? 'Level 1, '
                                            : 'Level 1'
                                          : ''}
                                        {competency?.level?.includes(2)
                                          ? competency?.level?.includes(3) || competency?.level?.includes(4)
                                            ? 'Level 2, '
                                            : 'Level 2'
                                          : ''}
                                        {competency?.level?.includes(3)
                                          ? competency?.level?.includes(4)
                                            ? 'Level 3, '
                                            : 'Level 3'
                                          : ''}
                                        {competency?.level?.includes(4) ? 'Level 4' : ''}{competency?.level?.length && competency?.level?.length > 0 ? ')' : ''}
                                      </div>
                                    )
                                  })}
                                </td>
                              </tr>
                            )}
                          </>
                          {!showForm && (
                            <>
                              <tr>
                                <th className="">Status</th>
                                <td>{values?.status}</td>
                              </tr>
                              <tr>
                                <th className="">Note</th>
                                <td>
                                  <span className={values?.status.toLowerCase() === 'approved' ? 'text-success' :
                                    values?.status.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}>
                                    {note}
                                  </span>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                        {showForm && (
                          <tfoot>
                            <tr className="bg-light text-dark text-center">
                              <th colSpan={2}>Approval Form</th>
                            </tr>
                            <tr>
                              <th>Status</th>
                              <td>
                                <div>
                                  <FormGroup check inline className="mb-3 ">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="status"
                                      value={values?.status}
                                      checked={
                                        values?.status === 'Approved'
                                          ? true
                                          : false
                                      }
                                      id="approve"
                                      onChange={() => {
                                        setFieldValue('status', 'Approved');
                                      }}
                                    />
                                    <label
                                      className="form-check-label text-success"
                                      htmlFor="approve"
                                    >
                                      Approve
                                    </label>
                                  </FormGroup>

                                  <FormGroup check inline className="mb-3 ">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="status"
                                      value={values?.status}
                                      checked={
                                        values?.status === 'Reject'
                                          ? true
                                          : false
                                      }
                                      id="reject"
                                      onChange={() => {
                                        setFieldValue('status', 'Reject');
                                      }}
                                    />
                                    <label
                                      className="form-check-label text-danger"
                                      htmlFor="reject"
                                    >
                                      Reject
                                    </label>
                                  </FormGroup>

                                  <FormGroup check inline className="mb-3 ">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="status"
                                      value={values?.status}
                                      id="correction"
                                      checked={
                                        values?.status === 'Correction'
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        setFieldValue('status', 'Correction');
                                      }}
                                    />
                                    <label
                                      className="form-check-label text-info"
                                      htmlFor="correction"
                                    >
                                      Correction
                                    </label>
                                  </FormGroup>

                                  {errors.status && touched.status && (
                                    <div className="text-danger">
                                      {errors.status}
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <th
                                className={
                                  values?.status?.toLowerCase() === 'reject' ||
                                    values?.status?.toLowerCase() === 'correction' ?
                                    'required-field' : ''}>
                                {values?.status} Note
                              </th>
                              <td>
                                <InputField
                                  type="textarea"
                                  placeholder={
                                    'Enter ' + values?.status + ' Note'
                                  }
                                  invalid={{ errors, touched }}
                                  {...getFieldProps('message')}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                {showForm && (
                                  <Button
                                    type="submit"
                                    text="Update"
                                    className="btn btn-success"
                                    disabled={submitting}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleSubmit();
                                    }}
                                  ></Button>
                                )}
                                {/* <Button
                                  type="button"
                                  text="Close"
                                  className="btn btn-danger"
                                  onClick={() => {
                                    setShowModal(!showModal);
                                  }}
                                ></Button> */}
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <CardBody className="text-center bg-light bg-opacity-50">
                          <div className="mx-auto mb-4">
                            {logDetails['Trainee Details']
                              ?.registration_user_info?.personal_info
                              ?.picture && (
                                <img
                                  src={
                                    config.APP_URL +
                                    logDetails['Trainee Details']
                                      ?.registration_user_info?.personal_info
                                      ?.picture
                                  }
                                  alt="Profile Photo"
                                  data-holder-rendered="true"
                                  className="avatar-xl rounded-circle img-thumbnail"
                                />
                              )}
                          </div>
                          <h5 className="font-size-15 fw-bold mb-1">
                            {
                              logDetails['Trainee Details']
                                ?.registration_user_info?.personal_info?.full_name
                            }
                          </h5>
                          <p className="text-muted mb-2">
                            <span className="fw-bold">
                              {
                                logDetails['Trainee Details']
                                  ?.registration_user_info?.registration_type
                              }
                            </span>
                          </p>

                          <p className="text-muted mb-2">
                            <span className="fw-bold">BMDC No.:&nbsp;</span>
                            {
                              logDetails['Trainee Details']
                                ?.registration_user_info?.bmdc_registration_no
                            }
                          </p>

                          <p className="text-muted mb-2">
                            <span className="fw-bold">Mobile:&nbsp;</span>
                            {
                              logDetails['Trainee Details']
                                ?.registration_user_info?.personal_info?.mobile
                            }
                          </p>

                          <p className="text-muted mb-2">
                            <span className="fw-bold">Email:&nbsp;</span>
                            {
                              logDetails['Trainee Details']
                                ?.registration_user_info?.personal_info?.email
                            }
                          </p>
                          <p className="text-muted mb-2">
                            <span className="fw-bold">Subject:&nbsp;</span>
                            {logDetails['Trainee Details']?.subject_name}
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default ELogBookSuperVisorList;
