import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { config } from "src/config";


interface Props {
  isOpen: boolean;
  toggle: any;
  data: any;
}

const AttachmentModal = (props: Props) => {

  const { isOpen, toggle, data } = props;
  const fcpsPartOneInfo = data?.registration?.educational_infos?.find((item: any) => item?.name_of_the_degree === 'FCPS-Part-1');
  const bdsInfo = data?.registration?.educational_infos?.find((item: any) => item?.name_of_the_degree === 'BDS');

  console.log(data)

  const arr = [
    { label: 'Applicant’s signature', value: 'applicant_signature' },
    { label: 'Applicant’s photograph', value: 'recent_photograph' },
    { label: 'Congratulation letter', value: 'congratulation_letter' },
    { label: 'Certificate of MBBS/BDS', value: 'mbbs_certificate_pdf' },
    { label: 'Training certificates', value: 'training_certifcate' },
    { label: 'Bank Cheque book of the applicant', value: 'bank_cheque' },
    { label: 'National ID card', value: 'national_id' },
    { label: 'Joining letter/ Testimonial', value: 'testimoninal' },
    { label: 'Other necessary documents', value: 'others_document' },
  ];

  return (
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      toggle={toggle}
      size="xl"
    >
      <ModalHeader toggle={toggle}>Attachments</ModalHeader>

      <ModalBody>
        <Row>
          <Col sm={3}>
            <Label>Applicant’s Signature with Date: </Label>
          </Col>
          <Col sm={3}>
            <a
              href={config.APP_URL + data?.registration?.personal_info?.signature}
              rel="noreferrer"
              target="_blank"
            >
              <p className="btn btn-sm btn-outline-primary w-100">
                View Applicant’s Signature
              </p>
            </a>
          </Col>

          <Col sm={3}>
            <Label>Recent passport size color photograph: </Label>
          </Col>
          <Col sm={3}>
            <a
              href={config.APP_URL + data?.registration?.personal_info?.picture}
              rel="noreferrer"
              target="_blank"
            >
              <p className="btn btn-sm btn-outline-primary w-100">
                View Applicant’s Photograph
              </p>
            </a>
          </Col>
          {fcpsPartOneInfo?.certificate_pdf &&
            <>
              <Col sm={3}>
                <Label>Congratulation letter of FCPS Part-I/ FCPS Part-I passed document: </Label>
              </Col>
              <Col sm={3}>
                <a
                  href={config.APP_URL + fcpsPartOneInfo?.certificate_pdf}
                  rel="noreferrer"
                  target="_blank"
                >
                  <p className="btn btn-sm btn-outline-primary w-100">
                    View FCPS Part-1 Document
                  </p>
                </a>
              </Col>
            </>}

          {bdsInfo?.certificate_pdf &&
            <>
              <Col sm={3}>
                <Label>Certificate of MBBS/BDS: </Label>
              </Col>
              <Col sm={3}>
                <a
                  href={config.APP_URL + bdsInfo?.certificate_pdf}
                  rel="noreferrer"
                  target="_blank"
                >
                  <p className="btn btn-sm btn-outline-primary w-100">
                    View BDS Certificate
                  </p>
                </a>
              </Col>
            </>}

          <Col sm={3}>
            <Label>Permanent registration certificate of BMDC: </Label>
          </Col>
          <Col sm={3}>
            <a
              href={config.APP_URL + data?.registration?.bmdc_certificate}
              rel="noreferrer"
              target="_blank"
            >
              <p className="btn btn-sm btn-outline-primary w-100">
                View BMDC Certificate
              </p>
            </a>
          </Col>
        </Row>

        <Row>
          {data?.files?.map((item: any) => (
            <Col key={item?.id} md={6}>
              <Row>
                <Col>
                  <Label>{arr?.find((i: any) => i?.value === item?.file_name)?.label}: </Label>
                </Col>
                <Col>
                  <a
                    href={config.APP_URL + item?.file}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p className="btn btn-sm btn-outline-primary w-100">
                      View {arr?.find((i: any) => i?.value === item?.file_name)?.label}
                    </p>
                  </a>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>

      </ModalBody>
    </Modal>
  );
};

export default AttachmentModal;
