import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { useEffect } from 'react';

interface Props {
    data: any;
    setShowAllowanceReport: any;
    setAllowanceFormId: any;
}

export const TrainingAllowancePDF = (props: Props) => {
    const { data, setShowAllowanceReport, setAllowanceFormId } = props;

    const image1 = new Image();
    image1.src = '/images/notice_header.png';
    const image2 = new Image();
    image2.src = '/images/notice_footer.png';

    let html =
        `
                <style>   
                    @media print {
                    
                        body {
                            margin: 0;
                            color: #000;
                            background-color: #fff;
                        }
                        .AllowanceReport {
                            padding: 0.5in;
                            color: #000000;
                        }
                    }                                                  
                
                </style>  
                        
               <div>
                    <!--1st page starting-->

                    <div class='d-flex justify-content-end'>
                        <span>SL No.</span>
                    </div>
                    <div class='d-flex justify-content-center mb-1'>
                        <span class='text-decoration h5'>BCPS Bill of Non-Governmental Trainees Allowances (Honorium)</span>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-12'>
                            <span>Name (Capital letter) : </span>
                            ${data?.registration?.personal_info?.full_name ? 
                                `<span>${data?.registration?.personal_info?.full_name?.toUpperCase()}</span>`
                                : 
                                `<span>
                                    ..............................................................................................................................................................................
                                </span>`
                            }
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-6'>
                            <span>BMDC Registration No : </span>
                            ${data?.registration?.bmdc_registration_no ? 
                                `<span>${data?.registration?.bmdc_registration_no}</span>`
                                : 
                                `<span>....................................................</span>`
                            }                                                        
                        </div>
                        <div class='col-6'>
                            <span>Applying for Honorarium : </span>                            
                            ${data?.registration?.current_session_and_supervisor_info?.session_start_date ? 
                                `<span>${moment(data?.registration?.current_session_and_supervisor_info?.session_start_date).format('DD-MMM-YYYY')}</span>`
                                : 
                                `<span>.....................................................</span>`
                            } 
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-6'>
                            <span>Institute Name : </span>                            
                            ${data?.registration?.current_session_and_supervisor_info?.supervisor?.institute_name ? 
                                `<span>${data?.registration?.current_session_and_supervisor_info?.supervisor?.institute_name}</span>`
                                : 
                                `<span>.........................................................................</span>`
                            } 
                        </div>
                        <div class='col-6'>
                            <span>Department : </span>                            
                            ${data?.registration?.subject_name ? 
                                `<span>${data?.registration?.subject_name}</span>`
                                : 
                                `<span>...............................................................................</span>`
                            } 
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-6'>
                            <span>Period of Training : </span>                            
                            ${data?.registration?.current_session_and_supervisor_info?.session_start_date && data?.registration?.current_session_and_supervisor_info?.session_end_date ? 
                                `<span>${moment(data?.registration?.current_session_and_supervisor_info?.session_start_date).format('DD-MMM-YYYY')} to  ${moment(data?.registration?.current_session_and_supervisor_info?.session_end_date).format('DD-MMM-YYYY')}</span>`
                                : 
                                `<span>............................................................................</span>`
                            } 
                        </div>
                        <div class='col-6'>
                            <span>Training Duration : </span>
                            <span>06 Months</span>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-6'>
                            <span>BCPS Registration No. : </span>                                                                       
                            ${data?.registration?.bcps_registration_no ? 
                                `<span>${data?.registration?.bcps_registration_no}</span>`
                                : 
                                `<span>.............................................</span>`
                            } 
                        </div>
                        <div class='col-6'>
                            <span>Mobile Number : </span>                                                                                                                            
                            ${data?.registration?.personal_info?.mobile ? 
                                `<span>${data?.registration?.personal_info?.mobile}</span>`
                                : 
                                `<<span>................................................................................</span>`
                            } 
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-6'>
                            <span>Name of the Bank : </span>                            
                            ${data?.registration?.bank_info?.bank_name ? 
                                `<span>${data?.registration?.bank_info?.bank_name}</span>`
                                : 
                                `<span>...............................................................................</span>`
                            } 
                        </div>
                        <div class='col-6'>
                            <span>Branch Name : </span>                            
                            ${data?.registration?.bank_info?.branch_name ? 
                                `<span>${data?.registration?.bank_info?.branch_name}</span>`
                                : 
                                `<span>..................................................................................................</span>`
                            } 
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-6'>
                            <span>Bank Account Number : </span>                            
                            ${data?.registration?.bank_info?.account_no ? 
                                `<span>${data?.registration?.bank_info?.account_no}</span>`
                                : 
                                `<span>......................................................................</span>`
                            }
                        </div>
                        <div class='col-6'>
                            <span>Routing Number : </span>                            
                            ${data?.registration?.bank_info?.routing_no ? 
                                `<span>${data?.registration?.bank_info?.routing_no}</span>`
                                : 
                                `<span>....................................................................................</span>`
                            }
                        </div>
                    </div>
                    <div class='row'>
                        <div class='col-12'>
                            <p>
                                I have received 25,000x6=1,50,000/- (In words: one lakh and fifty thousand) as training allowance (Honorium) for last 6 month period ${moment(data?.registration?.current_session_and_supervisor_info?.session_start_date).format('MMMM')} to  ${moment(data?.registration?.current_session_and_supervisor_info?.session_end_date).format('MMMM')} ${moment(data?.registration?.current_session_and_supervisor_info?.session_end_date).format('YYYY')}.
                            </p>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='col-12'>
                            <p>
                                I am ${data?.registration?.personal_info?.full_name ? `<span>${data?.registration?.personal_info?.full_name}</span>` : `<span>...............................................................................</span>`} declaring that all the information given by me are true. If the above information is proved false (even in partly), BCPS can take any legal action and I will be liable to refund the money.
                            </p>
                        </div>
                    </div>
                    <div class='row justify-content-end mb-4 mt-1'>
                        <div class='col-2 border border-dark text-center px-2 py-2'>
                            <span>Attach revenue stamp worth Tk 10</span>
                        </div>
                        <div class='col-6 border-top border-dark mt-4 ms-4 text-center'>
                            <span>Trainee's full name, Signature and Date</span>
                        </div>
                    </div>
                    <div class='row justify-content-between mb-2'>
                        <div class='col-4 border-top border-dark text-center'>
                            <span>Signature and seal of Supervisor</span>
                        </div>
                        <div class='col-6 border-top border-dark text-center'>
                            <span>Signature and seal of Director (Hospital/Superintendent)</span>
                        </div>
                    </div>
                    <div class='row mb-1'>
                        <div class='col-12 border-dark border-bottom'>
                            
                        </div>
                    </div>
                    <div class='d-flex justify-content-center mb-2'>
                        <span class='text-decoration-underline'>For Use of RTM Department</span>
                    </div>
                    <div class='row mb-5'>
                        <div class='col-12'>
                            <span>A provisional training certificate and relevant documents duly submitted by the trainee to RTM department and training allowances may be disbursed to the trainees Bank Account on the basis of submitted documents therefor.</span>
                        </div>
                    </div>
                    <div class='row justify-content-between mb-3'>
                        <div class='col-3 border-top border-dark text-center'>
                            <span><strong>Check by</strong></span>
                        </div>
                        <div class='col-3 border-top border-dark text-center'>
                            <span><strong>Assistant Director (RTM)</strong></span>
                        </div>
                        <div class='col-3 border-top border-dark text-center'>
                            <span><strong>Honorary Director (RTM)</strong></span>
                        </div>
                    </div>
                    <div class='row mb-2'>
                        <div class='col-12 border-dark border-bottom'>
                            
                        </div>
                    </div>
                    <div class='d-flex justify-content-center mb-1'>
                        <span class='text-decoration-underline'>For Use of Accounts and Administration Department</span>
                    </div>
                    <div class='row mb-2'>
                        <div class='col-12'>
                            <p>
                                As per the bill submitted by the trainee to RTM, BCPS report for Honorary training allowances gratuity received from the government in the allowance support sector (Code No. 3631102) total of 06 (six) months of allowances 25,000x6=1,50,000/- (In words: one lakh and fifty thousand) only sanctioned and paid.
                            </p>
                        </div>
                    </div>
                    <div class='row justify-content-between mb-4'>
                        <div class='col-3 text-center'>
                            <span>Account Officer</span>
                        </div>
                        <div class='col-3 text-center'>
                            <span>Additional Director (Finance)</span>
                        </div>
                        <div class='col-3 text-center'>
                            <span>Deputy Director (Admin)</span>
                        </div>
                        <div class='col-3 text-center'>
                            <span>Additional Director (Admin)</span>
                        </div>
                    </div>
                    <div class='row justify-content-between'>
                        <div class='col-3 text-center'>
                            <span>Director (Admin)</span>
                        </div>
                        <div class='col-2 text-center'>
                            <span>Secretary</span>
                        </div>
                        <div class='col-2 text-center'>
                            <span>Treasurer</span>
                        </div>
                    </div>

                    <!--

                    2nd page starting

                    <div class='d-flex mb-3'>
                        <strong><span class='text-decoration-underline'>অবৈতনিক প্রশিক্ষণার্থীদের ভাতাদি বাবদ সহায়তা (পারিতোষিক) প্রদান বিল ফরমটি জমাদানের নিয়মাবলীঃ</span></strong>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-12'>
                            <ul class='list-unstyled'>
                                <li class='mb-2'>
                                    ১. প্রশিক্ষণ সময় বলতে বর্তমান ৬ মাস প্রশিক্ষন সময়কাল লিখতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ২. প্রশিক্ষণার্থীকে অবশ্যই বিসিপিএস কর্তৃক স্বীকৃত প্রতিষ্ঠানে প্রশিক্ষণ গ্রহণ করতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ৩. আবেদনকারীর রেজিষ্ট্রেশন নম্বরে এফসিপিএস পার্ট-১ পাশের পর রেজিষ্ট্রিকৃত রেজিষ্ট্রেশন নম্বর (যা আইটি বিভাগ সরবরাহ করেছে) লিখতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ৪. পারিতোষিক বিলে অবশ্যই ১০ টাকা মূল্যের রেভিনিউ ষ্ট্যাম্প নির্দিষ্ট স্থানে সংযুক্ত করতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ৫. এফসিপিএস ১ম পর্ব পাসকৃত অবৈতনিক প্রশিক্ষণার্থীদের পারিতোষিক বিল জমাদানের ক্ষেত্রে আবেদনকারীকে পূর্বে আবেদনকৃত ফরমে উল্লেখিত <strong>সচল ব্যাংক হিসাব নম্বর</strong> লিখতে হবে। অন্যথায় টাকা পেতে বিলম্ব হলে/ না পেলে বিসিপিএস কর্তৃপক্ষ দায়ী থাকবে না।
                                </li>
                                <li class='mb-2'>
                                    ৬. পারিতোষিক বিল বিসিপিএস এর আরটিএম বিভাগে জমাদানের সময় আবেদনকারীদের পারিতোষিক বিলে অবৈতনিক প্রশিক্ষণার্থীদের রেজিষ্ট্রেশন খাতায় উল্লেখিত ক্রমিক নম্বর বিল ফরমের উপরে ডান পাশে লিখে জমা দিতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ৭. ইতঃপূর্বে যাঁরা অবৈতনিক প্রশিক্ষণার্থী হিসেবে পারিতোষিকের জন্য আবেদন করেছেন <strong>কেবলমাত্র তাঁরাই</strong> বিল ফরম জমা দিবেন এবং পূর্বের কোন তথ্য পরিবর্তন হলে আরটিএম বিভাগের অনারারি পরিচালক বরাবর লিখিত আবেদনের মাধ্যমে অবহিত করতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ৮. এফসিপিএস ১ম পর্ব পাসকৃত অবৈতনিক প্রশিক্ষণার্থীদের পারিতোষিক বিল জমাদানের ক্ষেত্রে যে সকল আবেদনকারী এফসিপিএস কোর্স ব্যতীত রেসিডেন্সি কোর্স/ ডিপ্লোমা কোর্স/ পেইড ট্রেনিং/ চাকুরিতে আছেন তাঁদেরকে পারিতোষিক বিল জমা না দেয়ার জন্য অনুরোধ করা হলো। তাঁরা এই অনারিয়ামের আওতাভুক্ত নন।
                                </li>
                                <li class='mb-2'>
                                    ৯. নির্ধারিত সময়ের মধ্যে অবশ্যই বিল ফরম দাখিল করতে হবে।
                                </li>
                                <li>
                                    ১০. অসম্পূর্ন ও ত্রুটিপূর্ণ বিল ফরম গ্রহণযোগ্য হবে না।
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class='d-flex mb-3'>
                        <strong><span>বিঃদ্রঃ</span></strong>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-12'>
                            <span>এফসিপিএস ১ম পর্ব পাস করা অবৈতনিক প্রশিক্ষণার্থীগণ একবার ভাতা প্রাপ্ত হওয়ার পর বিরতি দিয়ে পুনরায় প্রশিক্ষণ শুরু করলে নিম্নলিখিত শর্ত সাপেক্ষে প্রশিক্ষণ ভাতা প্রাপ্য হবেনঃ</span>
                        </div>
                    </div>
                    <div class='row mb-1 ms-3'>
                        <div class='col-12'>
                            <ul class='list-unstyled'>
                                <li class='mb-2'>
                                    ১) প্রশিক্ষণ থেকে বিরতি নেয়ার পূর্বে প্রশিক্ষণার্থীকে অবশ্যই কলেজের আরটিএম বিভাগ ও সংশ্লিষ্ট প্রতিষ্ঠানের কর্তৃপক্ষের নিকট থেকে অনুমতি নিতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ২) বিরতি নেয়ার পর পুনরায় প্রশিক্ষণে যোগদান করলে প্রশিক্ষণার্থীকে কলেজের আরটিএম বিভাগে লিখিত আবেদন সহ সংশ্লিষ্ট প্রতিষ্ঠানের যোগদানপত্র এবং সংশ্লিষ্ট যাবতীয় কাগজপত্রাদি কলেজের আরটিএম বিভাগে জমা দিতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ৩) ০৫ বছর মেয়াদী ফেলোশীপ প্রোগ্রামের আওতায় প্রশিক্ষণ গ্রহণকালে যুক্তিসঙ্গত কারণে একজন প্রশিক্ষণার্থী সর্বোচ্চ ০৩ বার প্রশিক্ষণে বিরতি নিতে পারবেন।
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                -->
                
        `;

    useEffect(() => {
        setShowAllowanceReport(false);
        setAllowanceFormId('');
    }, []);

    const options = {
        margin: [20, 10, 10, 10],
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
    };

    html2pdf()
        .from(html)
        .set(options)
        .toPdf()
        .get('pdf')
        .then(function (pdf: any) {
            var totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(10);
                pdf.setTextColor(150);
                pdf.addImage(image1, "PNG", 0, 0, pdf.internal.pageSize.getWidth(), 20);
                pdf.addImage(image2, "PNG", 0, pdf.internal.pageSize.getHeight() - 10, pdf.internal.pageSize.getWidth(), 10);
            }

            window.open(pdf.output('bloburl'), '_blank');
        });

    return null;
};
