import { apiSlice } from 'src/rtk/api/apiSlice';
import * as allowanceUrl from 'src/helpers/url_helper';

export const trainingAllowanceApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['TrainingAllowance'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllTrainingAllowanceList: builder.query<APIResponseWithPagination<any>, string>({
        query: (url: string) => ({
          method: 'GET',
          url: `${allowanceUrl.TRAINING_ALLOWANCE_LIST_FOR_ADMIN}?${url}`,
        }),

        providesTags: ['TrainingAllowance'],
      }),

      getAllTrainingAllowanceListForBCPS: builder.query<APIResponseWithPagination<any>, string>({
        query: (url: string) => ({
          method: 'GET',
          url: `${allowanceUrl.TRAINING_ALLOWANCE_LIST_FOR_BCPS_ADMIN}?${url}`,
        }),

        providesTags: ['TrainingAllowance'],
      }),

      getAllTrainingAllowanceListForAccounts: builder.query<APIResponseWithPagination<any>, string>({
        query: (url: string) => ({
          method: 'GET',
          url: `${allowanceUrl.TRAINING_ALLOWANCE_LIST_FOR_ACCOUNTS_ADMIN}?${url}`,
        }),

        providesTags: ['TrainingAllowance'],
      }),

      getTrainingAllowanceListForTrainee: builder.query<APIResponse<any>, string>({
        query: (url: string) => ({
          method: 'GET',
          url: `${allowanceUrl.TRAINING_ALLOWANCE_LIST_FOR_TRAINEE}?${url}`,
        }),

        providesTags: ['TrainingAllowance'],
      }),

      createTrainingAllowance: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${allowanceUrl.CREATE_TRAINING_ALLOWANCE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['TrainingAllowance'],
      }),

      createEligibleStatusUpdate: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${allowanceUrl.CREATE_TRAINING_ALLOWANCE_ELIGIBLE_STATUS_UPDATE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['TrainingAllowance'],
      }),

      getAllowanceApprovalByRTMD: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${allowanceUrl.GET_ALLOWANCE_APPROVAL_STATUS_UPDATE_BY_RTMD}/${url}`,
        }),
        invalidatesTags: ['TrainingAllowance'],
      }),

      getAllowanceApprovalByBCPS: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${allowanceUrl.GET_ALLOWANCE_APPROVAL_STATUS_UPDATE_BY_BCPS}/${url}`,
        }),
        invalidatesTags: ['TrainingAllowance'],
      }),

      getAllowanceApprovalByACCOUNTS: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${allowanceUrl.GET_ALLOWANCE_APPROVAL_STATUS_UPDATE_BY_ACCOUNTS}/${url}`,
        }),
        invalidatesTags: ['TrainingAllowance'],
      }),

      deleteChangedRequest: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${allowanceUrl.THESIS_DISSERTATION_TRAINEE_DELETE_CHANGE_REQUEST}/${id}`,
        }),
        invalidatesTags: ['TrainingAllowance'],
      }),

    }),
  });

export const {
  useGetAllTrainingAllowanceListQuery,
  useGetAllTrainingAllowanceListForBCPSQuery,
  useGetAllTrainingAllowanceListForAccountsQuery,
  useGetTrainingAllowanceListForTraineeQuery,
  useCreateTrainingAllowanceMutation,
  useCreateEligibleStatusUpdateMutation,
  useGetAllowanceApprovalByRTMDMutation,
  useGetAllowanceApprovalByBCPSMutation,
  useGetAllowanceApprovalByACCOUNTSMutation,
  useDeleteChangedRequestMutation,
} = trainingAllowanceApi;
