import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row
} from 'reactstrap';
import defaultAvatar from 'src/assets/images/users/default-avatar.png';
import { DaynamicInputField, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import {
  useCreateByPostMutation,
  useGetDataQuery,
  useUpdateByPutMutation,
} from 'src/rtk/features/common/crudApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';
import formObj from './formObj';

const isConditionMatch = (field: any, values: any = {}) => {
  if (
    field['dependField'] !== undefined &&
    field['dependValue'] !== undefined &&
    values[field['dependField']] !== undefined &&
    field['condition']
  ) {
    let isMatch = true;

    switch (field['condition']) {
      case '===':
        isMatch = values[field['dependField']] === field['dependValue'];
        break;

      case '!==':
        isMatch = values[field['dependField']] !== field['dependValue'];
        break;

      case 'includes':
        isMatch = field['dependValue'].includes(values[field['dependField']]);
        break;

      default:
        break;
    }
    if (!isMatch) {
      return false;
    }
  }
  return true;
};

const UserForm = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [btnClicked, setBtnClicked] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(true);
  const [photo, setPhoto] = useState('');
  const [signature, setSignature] = useState('');

  let initialValues: any = {
    id: '',
  };
  let validationObj: any = {};
  let inputFields: any = [];
  let formFilds = formObj?.formFields || {};
  Object.keys(formFilds)
    .sort(function (a: any, b: any) {
      return parseInt(a.positionInForm) - parseInt(b.positionInForm);
    })
    .map((key) => {
      if (formFilds[key]['showInForm']) {
        let field = formFilds[key];

        if (
          id &&
          ['password', 'password_confirmation'].includes(field['name'])
        ) {
          field['showInForm'] = false;
        }

        if (
          field['type'] === 'checkbox' &&
          typeof field['default'] === 'string'
        ) {
          field['default'] = [field['default'].trim()];
        } else if (
          field['type'] === 'radio' &&
          typeof field['default'] === 'object'
        ) {
          field['default'] = field['default'].join(' ').trim();
        } else if (field['type'] === 'date') {
          field['default'] = field?.options?.defaultDate || '';
        }

        if (isConditionMatch(field, initialValues)) {
          initialValues[field['name']] = field['default'];
          inputFields.push(formFilds[key]);

          if (field['isRequired']) {
            validationObj[field['name']] = field['validation'];
            if (id && formObj?.isUpdateByPatch && (field['name'] === 'photo' || field['name'] === 'signature')) {
              validationObj[field['name']] = field['validationForUpdate'];
            }
          }
        }
      }
    });

  Object.keys(formFilds)
    .sort(function (a: any, b: any) {
      return parseInt(a.positionInForm) - parseInt(b.positionInForm);
    })
    .map((key) => {
      if (!formFilds[key]['showInForm']) {
        let field = formFilds[key];

        if (
          !id &&
          ['password', 'password_confirmation'].includes(field['name'])
        ) {
          field['showInForm'] = true;
        }
      }
    });

  const [createByPost, { isSuccess, isError, error, isLoading: isCreateLoading }] =
    useCreateByPostMutation();
  const err: CustomError = error as CustomError;

  const [
    updateByPatch,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError, isLoading: isUpdateLoading },
  ] = useUpdateByPutMutation();
  const updateErr: CustomError = updateError as CustomError;

  useEffect(() => {
    if (isUpdateSuccess) {
      toast.success(formObj.title + ' Updated Successfully');
      history.push(formObj.listUrl);
    }
    if (isUpdateError) {
      toast.error(updateErr?.message || 'Something Went Wrong');
      setFromErrors(updateErr.data, setErrors);
    }
  }, [isUpdateSuccess, isUpdateError]);

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    setValues,
    setFieldValue,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({ ...validationObj }),
    onSubmit: (values) => {
      console.log(values);
      if (id) {
        if (formObj?.isUpdateByPatch) {
          // const updateUrl = formObj.updateAPIUrl + putUrlString(id, values);
          const formDataObj: any = new FormData();

          Object.keys(values).forEach((key: string) => {
            if (key === 'roles') {
              values['roles'].forEach((item: any, index: number) => {
                formDataObj.append(`roles[${index}]`, item);
              });
            } else if (key === 'status') {
              if (values[key] === 'true' || values[key] === 'false') {
                var isTrueSet = (values[key] === 'true');
                formDataObj.append(key, isTrueSet ? 1 : 0);
              }
              else if (values[key] === true || values[key] === false) {
                formDataObj.append(key, values[key] ? 1 : 0);
              }
            } else {
              formDataObj.append(key, values[key]);
            }
          });

          let postData = {
            queryUrl: formObj['createAPIUrl'],
            formData: formDataObj,
          };

          createByPost(postData);
        } else {

          const formDataObj: any = new FormData();

          Object.keys(values).forEach((key: string) => {
            if (key === 'roles') {
              values['roles'].forEach((item: any, index: number) => {
                formDataObj.append(`roles[${index}]`, item);
              });
            } else if (key === 'status') {
              formDataObj.append(key, values[key] === 'true' ? 1 : 0);
            } else {
              formDataObj.append(key, values[key]);
            }
          });

          let postData = {
            queryUrl: formObj['createAPIUrl'] + '/' + id,
            formData: formDataObj,
          };

          createByPost(postData);
        }
      } else {
        const formDataObj: any = new FormData();

        Object.keys(values).forEach((key: string) => {
          if (key === 'roles') {
            values['roles'].forEach((item: any, index: number) => {
              formDataObj.append(`roles[${index}]`, item);
            });
          } else if (key === 'status') {
            if (values[key] === 'true' || values[key] === 'false') {
              var isTrueSet = (values[key] === 'true');
              formDataObj.append(key, isTrueSet ? 1 : 0);
            }
            else if (values[key] === true || values[key] === false) {
              formDataObj.append(key, values[key] ? 1 : 0);
            }
          } else {
            formDataObj.append(key, values[key]);
          }
        });

        let postData = {
          queryUrl: formObj['createAPIUrl'],
          formData: formDataObj,
        };

        createByPost(postData);
      }
    },
  });

  if (id) {
    const { data: updatedData, isError: isExamFetchError } = useGetDataQuery(
      `${formObj.getDataByIdAPIUrl}${id}`,
    );

    useEffect(() => {
      if (updatedData && !isExamFetchError) {
        const data: any = (updatedData as any).data;

        let assignRoles = data?.roles?.map((item: any) => {
          return item.name;
        });
        data?.picture &&
          setPhoto(
            `${config?.APP_URL}${data?.picture}`,
          );
        data?.signature &&
          setSignature(
            `${config?.APP_URL}${data?.signature}`,
          );

        setValues({
          id: id || '',
          username: data.username || '',
          name: data.name || '',
          email: data.email || '',
          roles: assignRoles || [],
          mobile: data.mobile || '',
          status: data.status,
          signature: '',
          photo: '',
        });
      }
    }, [updatedData, isExamFetchError]);
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success(formObj.title + ' Saved Successfully');
      history.push(formObj.listUrl);
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  const handlePictureSelect = (event: any) => {
    const selectedPicture = event.target.files[0];
    setPhoto(URL.createObjectURL(selectedPicture));
  };

  const handleSignatureSelect = (event: any) => {
    const selectedSignature = event.target.files[0];
    setSignature(URL.createObjectURL(selectedSignature));
  };

  return (
    <React.Fragment>
      {isCreateLoading || isUpdateLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <div className="page-content">
        <Container fluid>
          {formObj.breadcrumbItemForForm && (
            <BetterBreadcrumb
              title={
                id === undefined
                  ? 'Add ' + formObj?.title
                  : 'Update ' + formObj?.title
              }
              breadcrumbItem={formObj.breadcrumbItemForForm}
            />
          )}
          <div className="row align-items-center justify-content-center">
            <Form
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              onReset={(e) => {
                e.preventDefault();
                resetForm();
              }}
            >
              <div className="card">
                <div className="d-flex card-header">
                  <h2 className="card-title mb-0 flex-grow-1">
                    {/* <i className="fa fa-plus me-2" />
                    Logbook Entry */}
                  </h2>
                  <div className="flex-shrink-0 align-self-end">
                    <Link
                      to="/users"
                      className="btn btn-success waves-effect waves-light"
                    >
                      <i className="fas fa-arrow-left me-2" /> Back
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <Row>
                    {inputFields.length > 0 &&
                      inputFields.map((field: any, key: any) => {
                        if (!['hidden', 'submit'].includes(field['type'])) {
                          field['type'] = field['type'];

                          if (!isConditionMatch(field, values)) {
                            if (values[field['name']] !== field['default']) {
                              setFieldValue(field['name'], field['default']);
                            }
                            return false;
                          }

                          return (
                            <Col
                              key={field['name'] + key}
                              md={field['colSize']}
                              sm={12}
                            >
                              <FormGroup className="mb-3">
                                <>
                                  {['modal'].includes(field['type']) && (
                                    <DaynamicInputField
                                      label={field['label']}
                                      name={field['name']}
                                      type={field['type']}
                                      placeholder={field['label']}
                                      options={field['data']}
                                      dataApi={field['dataApi']}
                                      value={
                                        values[field['name']] !== undefined
                                          ? values[field['name']]
                                          : field['default']
                                      }
                                      isMulti={field['isMulti']}
                                      required={field['isRequired']}
                                      invalid={{ errors, touched }}
                                      onChange={(
                                        data: any,
                                        isSelected: boolean = false,
                                      ) => {
                                        if (data === null) {
                                          setFieldValue(field['name'], '');
                                          field['data'] = [];
                                        } else if (
                                          data &&
                                          typeof data === 'string'
                                        ) {
                                          let isSelected =
                                            values[field['name']].includes(
                                              data,
                                            );

                                          if (isSelected) {
                                            setFieldValue(field['name'], '');
                                            field['data'] = [];
                                          } else {
                                            setFieldValue(field['name'], data);
                                            field['data'] = [data];
                                          }
                                        } else if (
                                          data &&
                                          typeof data === 'object' &&
                                          data?.length === undefined
                                        ) {
                                          let isSelected =
                                            values[field['name']] ===
                                            data?.value;

                                          if (isSelected) {
                                            setFieldValue(field['name'], '');
                                            field['data'] = [];
                                          } else {
                                            setFieldValue(
                                              field['name'],
                                              data?.value || '',
                                            );
                                            field['data'] = data;
                                          }
                                        } else if (
                                          data &&
                                          typeof data === 'object' &&
                                          data?.length > 0
                                        ) {
                                          if (!isSelected) {
                                            let filterData = field[
                                              'data'
                                            ].filter((item: any) => {
                                              return (
                                                item.value !== data?.[0].value
                                              );
                                            });

                                            setFieldValue(
                                              field['name'],
                                              filterData.map(
                                                (item: any) => item.value,
                                              ),
                                            );
                                            field['data'] = filterData;
                                          } else {
                                            setFieldValue(
                                              field['name'],
                                              data.map(
                                                (item: any) => item.value,
                                              ),
                                            );
                                            field['data'] = data;
                                          }
                                        }
                                      }}
                                    />
                                  )}

                                  {['select'].includes(field['type']) && (
                                    field['name'] !== 'roles' ?
                                      <DaynamicInputField
                                        label={field['label']}
                                        name={field['name']}
                                        type={field['type']}
                                        placeholder={field['label']}
                                        options={field['data']}
                                        dataApi={field['dataApi']}
                                        value={
                                          values[field['name']] !== undefined
                                            ? values[field['name']]
                                            : field['default']
                                        }
                                        isMulti={field['isMulti']}
                                        required={field['isRequired']}
                                        invalid={{ errors, touched }}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        styles={{ menuPortal: (provided: any, state: any) => ({ ...provided, zIndex: '9999', }) }}
                                        onChange={(data: any) => {
                                          if (data === null) {
                                            setFieldValue(field['name'], '');
                                          } else if (
                                            data &&
                                            data?.length === undefined &&
                                            data.value !== undefined
                                          ) {
                                            setFieldValue(
                                              field['name'],
                                              data.value,
                                            );
                                          } else if (
                                            data &&
                                            data?.length !== undefined
                                          ) {
                                            setFieldValue(
                                              field['name'],
                                              data.map((item: any) => item.value),
                                            );
                                          }
                                        }}
                                      />
                                      :
                                      <DaynamicInputField
                                        label={field['label']}
                                        name={field['name']}
                                        type={field['type']}
                                        placeholder={field['label']}
                                        options={useGetDataQuery(field['dataApi'].url)?.data?.data?.map((op: any, index: number) => {
                                          return { label: op.name, value: op.name };
                                        })?.filter((op: any, index: number) => {
                                          return !['trainee', 'supervisor', 'fellow'].includes(op.value);
                                        })}
                                        // dataApi={field['dataApi']}
                                        value={
                                          values[field['name']] !== undefined
                                            ? values[field['name']]
                                            : field['default']
                                        }
                                        isMulti={field['isMulti']}
                                        required={field['isRequired']}
                                        invalid={{ errors, touched }}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        styles={{ menuPortal: (provided: any, state: any) => ({ ...provided, zIndex: '9999', }) }}
                                        onChange={(data: any) => {
                                          if (data === null) {
                                            setFieldValue(field['name'], '');
                                          } else if (
                                            data &&
                                            data?.length === undefined &&
                                            data.value !== undefined
                                          ) {
                                            setFieldValue(
                                              field['name'],
                                              data.value,
                                            );
                                          } else if (
                                            data &&
                                            data?.length !== undefined
                                          ) {
                                            setFieldValue(
                                              field['name'],
                                              data.map((item: any) => item.value),
                                            );
                                          }
                                        }}
                                      />
                                  )}

                                  {['date'].includes(field['type']) && (
                                    <>
                                      <DaynamicInputField
                                        label={field['label']}
                                        name={field['name']}
                                        type={field['type']}
                                        placeholder={field['label']}
                                        options={field['options']}
                                        invalid={{ errors, touched }}
                                        value={
                                          values[field['name']] !== undefined
                                            ? values[field['name']]
                                            : field?.options?.defaultDate
                                        }
                                        required={field['isRequired']}
                                        setData={(data: any) => {
                                          if (
                                            field?.options?.mode === 'range'
                                          ) {
                                            setFieldValue(
                                              field['name'],
                                              data[0],
                                            );
                                            setFieldValue(
                                              field['name2'],
                                              data[1],
                                            );
                                          } else {
                                            setFieldValue(
                                              field['name'],
                                              data.join(',').trim(),
                                            );
                                          }
                                        }}
                                      />
                                    </>
                                  )}

                                  {['radio', 'checkbox'].includes(
                                    field['type'],
                                  ) && (
                                      <DaynamicInputField
                                        label={field['label']}
                                        name={field['name']}
                                        type={field['type']}
                                        placeholder={field['label']}
                                        options={field['data']}
                                        dataApi={field['dataApi']}
                                        value={
                                          values[field['name']] !== undefined
                                            ? values[field['name']]
                                            : field['default']
                                        }
                                        isMulti={field['isMulti']}
                                        required={field['isRequired']}
                                        invalid={{ errors, touched }}
                                        onChange={(data: any) => {
                                          let selectedValue =
                                            data.target.value;
                                          if (field['type'] === 'checkbox') {
                                            let isChecked = data.target.checked;
                                            let oldValue = values[field['name']];

                                            let getIndex =
                                              oldValue.indexOf(selectedValue);
                                            if (isChecked && getIndex === -1) {
                                              oldValue.push(selectedValue);
                                            } else if (
                                              !isChecked &&
                                              getIndex > -1
                                            ) {
                                              oldValue.splice(getIndex, 1);
                                            }

                                            setFieldValue(
                                              field['name'],
                                              oldValue,
                                            );
                                          } else {
                                            setFieldValue(
                                              field['name'],
                                              selectedValue,
                                            );
                                          }
                                        }}
                                      />
                                    )}

                                  {['file'].includes(
                                    field['type'],
                                  ) && (
                                      field['label'] === 'Photograph' ?
                                        <div className="mb-3">
                                          <div className="image-upload-wrapper">
                                            <div className="image-preview">
                                              <img src={photo ? photo : defaultAvatar} alt="" />
                                            </div>
                                            <div className="image-upload">
                                              <Label for="picture" className="required-field">
                                                {field['label']}
                                              </Label>{' '}
                                              <span className="text-muted fw-light">
                                                (Format must be JPG,PNG,JPEG.)
                                              </span>
                                              <InputField
                                                type={field['type']}
                                                accept={field['accept']}
                                                name={field['name']}
                                                placeholder={field['placeholder']}
                                                onChange={(e) => {
                                                  setFieldValue(field['name'], e.target.files[0]);
                                                  handlePictureSelect(e);
                                                }}
                                                invalid={{ errors, touched }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        :
                                        field['label'] === 'Signature' ?
                                          <div className="mb-3">
                                            <div className="image-upload-wrapper">
                                              <div className="image-preview">
                                                <img src={signature ? signature : defaultAvatar} alt="" />
                                              </div>
                                              <div className="image-upload">
                                                <Label for="signature" className="required-field">
                                                  {field['label']}
                                                </Label>{' '}
                                                <span className="text-muted fw-light">
                                                  (Format must be JPG,PNG,JPEG.)
                                                </span>
                                                <InputField
                                                  type={field['type']}
                                                  accept={field['accept']}
                                                  name={field['name']}
                                                  placeholder={field['placeholder']}
                                                  onChange={(e) => {
                                                    setFieldValue(field['name'], e.target.files[0]);
                                                    handleSignatureSelect(e);
                                                  }}
                                                  invalid={{ errors, touched }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          <>
                                          </>
                                    )}

                                  {![
                                    'modal',
                                    'select',
                                    'radio',
                                    'checkbox',
                                    'date',
                                    'file',
                                  ].includes(field['type']) && (
                                      field['type'] === 'password' ?
                                        field['name'] === 'password' ?
                                          <>
                                            <Label
                                              className={
                                                field['isRequired']
                                                  ? 'form-label me-2 required-field text-capitalize'
                                                  : 'form-label me-2 text-capitalize'
                                              }
                                            >
                                              {field['label']}
                                            </Label>
                                            <div className="input-group password-input">
                                              <DaynamicInputField
                                                label={''}
                                                type={showPassword ? field['type'] : 'text'}
                                                placeholder={field['label']}
                                                required={field['isRequired']}
                                                // invalid={{ errors, touched }}
                                                {...getFieldProps(field['name'])}
                                              />
                                              <Button
                                                color="light"
                                                size="sm"
                                                onClick={() => setShowPassword(!showPassword)}
                                              >
                                                <i
                                                  className={`${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                                                    }`}
                                                />
                                              </Button>
                                            </div>
                                            {errors?.[field['name']] && touched?.[field['name']] ? (
                                              <div className='text-danger'>{errors?.[field['name']] as string}</div>
                                            ) : null}
                                          </>
                                          :
                                          <>
                                            <Label
                                              className={
                                                field['isRequired']
                                                  ? 'form-label me-2 required-field text-capitalize'
                                                  : 'form-label me-2 text-capitalize'
                                              }
                                            >
                                              {field['label']}
                                            </Label>
                                            <div className="input-group password-input">
                                              <DaynamicInputField
                                                label={''}
                                                type={showConfirmPassword ? field['type'] : 'text'}
                                                placeholder={field['label']}
                                                required={field['isRequired']}
                                                // invalid={{ errors, touched }}
                                                {...getFieldProps(field['name'])}
                                              />
                                              <Button
                                                color="light"
                                                size="sm"
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                              >
                                                <i
                                                  className={`${showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                                                    }`}
                                                />
                                              </Button>
                                            </div>
                                            {errors?.[field['name']] && touched?.[field['name']] ? (
                                              <div className='text-danger'>{errors?.[field['name']] as string}</div>
                                            ) : null}
                                          </>
                                        :
                                        <DaynamicInputField
                                          label={field['label']}
                                          type={field['type']}
                                          placeholder={field['label']}
                                          required={field['isRequired']}
                                          invalid={{ errors, touched }}
                                          readOnly={['Username', 'Email'].includes(field['label']) && id ? true : false}
                                          {...getFieldProps(field['name'])}
                                        />
                                    )}
                                </>
                              </FormGroup>
                            </Col>
                          );
                        }
                      })}
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Button
                        type="submit"
                        className="btn btn-success me-3"
                        onClick={(e) => {
                          setBtnClicked('submit');
                        }}
                      >
                        {id === undefined
                          ? formObj.submitBtnTitle
                            ? formObj.submitBtnTitle
                            : 'Submit'
                          : formObj.updateBtnTitle
                            ? formObj.updateBtnTitle
                            : 'Update'}
                      </Button>

                      {formObj.draftBtnTitle && (
                        <Button
                          type="submit"
                          className="btn btn-info"
                          onClick={(e) => {
                            setBtnClicked('save_as_draft');
                          }}
                        >
                          {formObj.draftBtnTitle}
                        </Button>
                      )}

                      {formObj.resetBtnTitle && (
                        <Button
                          type="reset"
                          onClick={(e) => {
                            setPhoto('');
                            setSignature('');
                            resetForm();
                          }}
                          className="btn btn-danger me-2"
                        >
                          {formObj.resetBtnTitle}
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {formObj.debug && (
                    <Row>
                      <Col sm={12}>{JSON.stringify(values)}</Col>
                    </Row>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default UserForm;
