import { useFormik } from 'formik';
import moment from 'moment';
// import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import * as url from 'src/helpers/url_helper';
import {
  useGetDataQuery,
  useUpdateByGetMutation,
} from 'src/rtk/features/common/crudApi';
import { useGetFormByLogbookTypeQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import * as Yup from 'yup';
import loader from '../../../assets/images/loader.gif';
import ProfileView from './ProfileView';

const SessionApprovalSuperVisorList = () => {
  let { id: statusValue } = useParams<{ id: string }>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [submitting, setSubmittingl] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<any>([]);
  const [statusName, setStatusName] = useState<string>('');
  // const [extraDetails, setExtraDetails] = useState<any[]>([]);

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');
  const generateQueryUrl = () => {
    return `${url.SUPER_VISOR_COURSE_LIST}?page=${page}&per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  //  const handleSort = (column: string, sortDirection: string) => {
  //    console.log(column, sortDirection);
  //  };

  const {
    data: eLogBooks,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
    isFetching
  } = useGetDataQuery(generateQueryUrl());
  const data: any = eLogBooks?.data || [];

  useEffect(() => {
    if (dataListLoading || !dataListLoadingSuccess) {
      toast.dismiss();
      // toast.loading('Data Loading...');
    } else {
      toast.dismiss();
      // toast.success('Data Load Successfully');
    }
  }, [dataListLoading, dataListLoadingSuccess]);

  useEffect(() => {
    if (dataListLoadingError) {
      toast.dismiss();
      toast.error('Data Loading error. Please try again later.');
    } else {
      toast.dismiss();
    }
  }, [dataListLoadingError]);

  const [updateELogBook, { isSuccess, isError, error, isLoading: isUpdateLoading }] =
    useUpdateByGetMutation();
  const err = error as CustomError;

  interface approvalStatus {
    id: string;
    status: string;
    message?: string;
  }

  const initialValues: approvalStatus = {
    id: '',
    status: '',
    message: '',
  };
  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      status: Yup.string().required('Status is required'),
      message: Yup.string().when('status', (status, schema) => {
        if (status && status !== 'Approve') {
          return schema.required(status + ' note is Required!');
        }
        return schema;
      }),
    }),
    onSubmit: (values) => {
      setSubmittingl(true);
      let updateUrl = `${url.SUPER_VISOR_COURSE_APPROVAL}/${values.id}?approve_type=${values.status}&note=${values.message}`;
      updateELogBook(updateUrl);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setSubmittingl(false);
      resetForm();
      setShowModal(!showModal);
      toast.success('Update Successfully');
    }
    if (isError) {
      setSubmittingl(false);
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  const viewHandler = (
    data: any = {},
    detailsId: string,
    status: string = '',
  ) => {
    toast.loading('Logbook Details Loading...');
    setShowModal(!showModal);
    resetForm();
    values['id'] = detailsId;
    values['status'] = '';
    if (
      ['pending', 'draft', 'correction', 'true', 'false'].includes(
        status.toLowerCase(),
      )
    ) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
    setUserInfo(data);
  };

  const print = (data: any[]) => {
    console.log(data);
  };

  const getDetailsData = (key = '', dataObj: any = {}) => {
    let perseData: any = {};

    if (
      dataObj['details_json'] &&
      typeof dataObj['details_json'] === 'string'
    ) {
      perseData = JSON.parse(dataObj.details_json);
    } else {
      perseData = dataObj;
    }

    return perseData[key] ? (
      <span dangerouslySetInnerHTML={{ __html: perseData[key] }}></span>
    ) : perseData[key.toLowerCase()] ? (
      <span dangerouslySetInnerHTML={{ __html: perseData[key] }}></span>
    ) : (
      ''
    );
  };

  interface SearchFields {
    search?: string;
    from_date?: string;
    to_date?: string;
    supervisors_approve_status?: string;
  }

  const searchInitialValues: SearchFields = {
    search: '',
    from_date: '',
    to_date: '',
    supervisors_approve_status: '',
  };

  const {
    handleSubmit: searchSubmit,
    getFieldProps: searchGetFieldProps,
    values: searchValues,
    // touched,
    // errors,
    resetForm: searchResetForm,
    setFieldValue: setSearchFieldValue,
    // setErrors,
    // isSubmitting,
  } = useFormik({
    initialValues: searchInitialValues,

    onSubmit: (searchValues) => {
      let s: string = '';

      if (searchValues.search !== '') {
        s = s + `&search=${searchValues.search}`;
      }

      if (searchValues.from_date !== '') {
        s = s + `&from_date=${searchValues.from_date}`;
      }

      if (searchValues.to_date !== '') {
        s = s + `&to_date=${searchValues.to_date}`;
      }

      if (searchValues.supervisors_approve_status !== '') {
        s =
          s +
          `&supervisors_approve_status=${searchValues.supervisors_approve_status}`;
      }

      setSearch(s);
      setPage('1');
      // setLimit(20);
    },
  });

  const { data: formSetups, isLoading: formSetupsLoading } =
    useGetFormByLogbookTypeQuery(2); // 2 for Academic Activities
  const forms =
    (formSetups?.data && formSetups?.data[0]?.entry_setup_master) || [];
  let formOptions: SelectOption[] = [
    ...forms?.map((e: LogbookEntrySetupMaster) => ({
      label: e.particulars!,
      value: e.particulars!,
    })),
  ];

  useEffect(() => {
    setStatusName(statusValue);
  }, []);

  useEffect(() => {
    if (['All', 'Current', 'Completed', 'Pending'].includes(statusName)) {
      setSearchFieldValue('supervisors_approve_status', statusName);

      let s: string = '';
      s = `&supervisors_approve_status=${statusName}`;
      setSearch(s);
    }
  }, [statusName]);

  useEffect(() => {
    setSearchFieldValue('supervisors_approve_status', 'Pending');

    let s: string = '';
    s = s + `&supervisors_approve_status=${'Pending'}`;
    setSearch(s);
  }, []);

  const columns: TableColumn<any>[] = [
    {
      name: '#',
      width: '50px',
      cell: (row, index) => <strong>{data.from + index}</strong>,
    },
    {
      name: <div>Photo</div>,
      selector: (row) => row.profile_info?.personal_info?.picture,
      cell: (row, index) => (
        <>
          {row.profile_info?.personal_info?.picture ? (
            <img
              src={config.APP_URL + row.profile_info?.personal_info?.picture}
              alt="Profile Photo"
              data-holder-rendered="true"
              className="img-thumbnail my-1 rounded-circle avatar-md"
            />
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      name: <div>Apply Date</div>,
      selector: (row) => row.created_at,
      cell: (row, index) =>
        row.created_at ? moment(row.created_at).format('DD/MMM/YYYY') : '',
    },

    {
      name: <div>Full Name</div>,
      selector: (row) => row.profile_info?.personal_info?.full_name,
      wrap: true,
    },

    {
      name: <div>BMDC Reg. No.</div>,
      selector: (row) => row.profile_info?.bmdc_registration_no,
    },
    {
      name: <div>Reg. Type</div>,
      selector: (row) => row.profile_info?.registration_type,
    },

    {
      name: <div>Exam Name</div>,
      selector: (row) => row.exam_name,
    },

    {
      name: <div>Subject Name</div>,
      selector: (row) => row.subject_name,
      width: '150px',
      wrap: true,
    },

    {
      name: <div>Status</div>,
      selector: (row) => row.supervisors_approve_status,
      cell: (row) => {
        switch (row.supervisors_approve_status) {
          case 'Reject':
            return (
              <>
                <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                  {/* {row.supervisors_approve_status} */}
                  {'Rejected'}
                </div>
              </>
            );

          case 'Draft':
            return (
              <>
                <div className="badge rounded-pill d-inline-block badge-draft font-size-12 fw-medium">
                  {row.supervisors_approve_status}
                </div>
              </>
            );

          case 'Approve':
            return (
              <>
                <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                  {/* {row.supervisors_approve_status} */}
                  {'Approved'}
                </div>
              </>
            );

          case 'Pending':
            return (
              <>
                <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                  {row.supervisors_approve_status}
                </div>
              </>
            );

          default:
            return (
              <>
                <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                  {row.supervisors_approve_status}
                </div>
              </>
            );
        }
      },
    },
    {
      name: <div>Action</div>,
      selector: (row) => row.id,
      cell: (row, index) => (
        <>
          <Button
            size="sm"
            color="info"
            type="button"
            text={`<span class="fas fa-eye" ></span> View`}
            onClick={() => {
              let detailsId = row.id;
              let supervisors_approve_status =
                row?.supervisors_approve_status || '';
              viewHandler(
                row.profile_info,
                detailsId,
                supervisors_approve_status,
              );
            }}
          />
        </>
      ),
    },
  ];

  document.title = 'Trainee Approval List | BCPS';

  return (
    <div className="page-content">
      {dataListLoading || isFetching || isUpdateLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          title="Trainee Approval List"
          breadcrumbItem={[
            { link: '/', name: 'Dashboard' },
            { link: '/supervisor/session-approval', name: 'Supervisor' },
            // { link: '/supervisor/session-approval', name: 'Session Approval' },
          ]}
        />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div className="gap-2 ">
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      searchSubmit();
                    }}
                  >
                    <div className="filter-wrapper mb-0">
                      {/* <FormGroup>
                        <Label for="form">Full Name</Label>
                        <InputField
                          type="text"
                          placeholder="Search here..."
                          {...searchGetFieldProps('search')}
                        />
                      </FormGroup> */}

                      {/* <FormGroup>
                            <Label for="type">Type</Label>
                            <InputField
                              type="select"
                              placeholder=""
                              options={[]}
                              invalid={{ errors, touched }}
                              {...getFieldProps('type')}
                            />
                          </FormGroup> */}

                      <FormGroup>
                        <Label for="from_date">From Date</Label>
                        <InputField
                          type="date"
                          placeholder=""
                          invalid={{ errors, touched }}
                          {...searchGetFieldProps('from_date')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="to_date">To Date</Label>
                        <InputField
                          type="date"
                          placeholder=""
                          invalid={{ errors, touched }}
                          {...searchGetFieldProps('to_date')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="status">Status</Label>
                        <InputField
                          type="select"
                          placeholder=""
                          options={[
                            { label: 'All', value: 'All' },
                            { label: 'Approved', value: 'Approve' },
                            { label: 'Pending', value: 'Pending' },
                            { label: 'Rejected', value: 'Reject' },                            
                            { label: 'Current', value: 'Current' },
                            { label: 'Completed', value: 'Completed' },
                          ]}
                          invalid={{ errors, touched }}
                          {...searchGetFieldProps('supervisors_approve_status')}
                        />
                      </FormGroup>

                      <FormGroup className="btn-column mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          text="Reset"
                          onClick={() => {
                            searchResetForm();
                            searchSubmit();
                          }}
                        />
                        <Button
                          type="button"
                          text="Search"
                          onClick={() => {
                            searchSubmit();
                          }}
                        />
                      </FormGroup>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
            <DataTable
              // title="Academic Activities List"
              columns={columns}
              data={data.data ? data.data : []}
              pagination
              paginationServer
              // selectableRows
              // sortServer
              // onSort={handleSort}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={dataListLoading}
              paginationTotalRows={data.total}
              // expandableRows
              // expandableRowsComponent={ExpandedComponent}
              // fixedHeader
              // fixedHeaderScrollHeight="500px"
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              persistTableHead={true}
            />
          </CardBody>
        </Card>
      </Container>
      <Modal
        onOpened={function () {
          toast.dismiss();
        }}
        isOpen={showModal}
        scrollable={false}
        size="lg"
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <Form
          className="custom-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          onReset={(e) => {
            e.preventDefault();
            resetForm();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Details Info
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowModal(false);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <ProfileView regNo={userInfo?.registration_no} />

            {showForm && (
              <>
                <h6 className="block-heading">Session Approval Form</h6>
                <table className="table table-bordered table-sm">
                  <tbody>
                    <tr>
                      <th>Status</th>
                      <td>
                        <div>
                          <FormGroup check inline className="mb-3 ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value={values.status}
                              checked={
                                values.status === 'Approve' ? true : false
                              }
                              id="approve"
                              onChange={() => {
                                setFieldValue('status', 'Approve');
                              }}
                            />
                            <label
                              className="form-check-label text-success"
                              htmlFor="approve"
                            >
                              Approve
                            </label>
                          </FormGroup>

                          <FormGroup check inline className="mb-3 ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value={values.status}
                              checked={
                                values.status === 'Reject' ? true : false
                              }
                              id="reject"
                              onChange={() => {
                                setFieldValue('status', 'Reject');
                              }}
                            />
                            <label
                              className="form-check-label text-danger"
                              htmlFor="reject"
                            >
                              Reject
                            </label>
                          </FormGroup>

                          {/* <FormGroup check inline className="mb-3 ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value={values.status}
                              id="correction"
                              checked={
                                values.status === 'Correction' ? true : false
                              }
                              onChange={() => {
                                setFieldValue('status', 'Correction');
                              }}
                            />
                            <label
                              className="form-check-label text-info"
                              htmlFor="correction"
                            >
                              Correction
                            </label>
                          </FormGroup> */}

                          {errors.status && touched.status && (
                            <div className="text-danger">{errors.status}</div>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className={`${values.status === 'Reject' ? 'required-field' : ''}`}>{values.status} Note</th>
                      <td>
                        <InputField
                          type="textarea"
                          placeholder={'Enter ' + values.status + ' Note'}
                          invalid={{ errors, touched }}
                          {...getFieldProps('message')}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
          <div className="modal-footer">
            {showForm && (
              <Button
                type="submit"
                text="Update"
                className="btn btn-success"
                disabled={submitting}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              ></Button>
            )}
            <Button
              type="button"
              text="Close"
              className="btn btn-danger"
              onClick={() => {
                setShowModal(!showModal);
              }}
            ></Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default SessionApprovalSuperVisorList;
